import * as React from 'react'
import { identity } from 'lodash'

/**
 * Return the previous state value
 */
export function usePrevious<T>(
  value: T,
  discriminator: (prev: T) => unknown = identity
): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef(value)

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value
  }, [discriminator(value)]) // Only re-run if value changes

  return ref.current
}

export const useAsync = <T>(
  fn: () => Promise<T> | undefined,
  deps?: unknown[]
): { result: T } | undefined => {
  const [res, setRes] = React.useState<{ result: T }>()
  React.useEffect(() => {
    ;(async () => {
      if (!deps) {
        return
      }
      const q = fn()

      if (q) {
        setRes({ result: await q })
      }
    })()
  }, [setRes, fn, ...deps])

  return res
}
