/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { FiberManualRecord } from '@material-ui/icons'
export const TAB_BAR_HEIGHT_DESKTOP_PX = 30
export const TAB_BAR_HEIGHT_MOBILE_PX = 50

const tabs = [
  {
    shortCode: 'action',
    name: 'Take action',
    url: '/'
  },
  {
    shortCode: 'livestreams',
    name: 'Livestreams',
    url: '/livestreams'
  }
]

const liveDot = (content: string) => (
  <span>
    <FiberManualRecord
      color="secondary"
      fontSize="inherit"
      sx={{ color: 'red' }}
    />
    <span> {content}</span>
  </span>
)

interface ITabProps {
  isActive: boolean
  textContent: string
  url: string
}
const Tab: React.FC<ITabProps> = props => {
  const { isActive, textContent, url } = props
  return (
    <a
      css={css`
        background-color: ${isActive ? '#3f3f3f' : '#323232'};
        color: ${isActive ? '#ffffff' : '#767676'};
        width: ${(100 / tabs.length).toFixed(5)}%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
      `}
      href={isActive ? undefined : url}
    >
      {textContent === 'Livestreams' ? liveDot(textContent) : textContent}
    </a>
  )
}

interface ITabBarProps {
  currentSite: 'mcm' | 'mptw' | 'mpd'
}
export const TabBar: React.FC<ITabBarProps> = props => {
  const { currentSite } = props

  return (
    <nav
      css={css`
        height: ${TAB_BAR_HEIGHT_MOBILE_PX}px;
        width: 100%;

        @media screen and (min-width: 1024px) {
          height: ${TAB_BAR_HEIGHT_DESKTOP_PX}px;
        }

        display: flex;
        flex-shrink: 0;
        flex-grow: 0;

        a {
          border-left: 2px solid #767676;
        }

        a:first-of-type {
          border-left: none;
        }
        a:last-child {
          border-right: none;
        }
      `}
    >
      {tabs.map(({ shortCode, name, url }) => {
        return (
          <Tab
            key={`tab-bar__tab--${url}`}
            isActive={currentSite === shortCode}
            textContent={name}
            url={url}
          />
        )
      })}
    </nav>
  )
}

export default TabBar
