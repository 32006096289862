/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { useCookie } from '@use-hook/use-cookie'
import { Button } from '@material-ui/core'
import { SpringUp } from './SpringUp'

const CookieConsentBanner: React.FC<{
  onConsent: (consented: boolean) => void
}> = ({ onConsent }) => {
  const [consent, setConsent] = useCookie(
    'my-campaign-map-cookie-consent',
    false
  )

  React.useEffect(() => {
    onConsent(consent)
  }, [consent])

  return !consent ? (
    <SpringUp
      sx={{ bottom: 0, left: 0, position: 'fixed', width: '100%', zIndex: 999 }}
    >
      <div
        sx={{ bg: 'primary', color: 'white', textAlign: 'center', p: 1, pl: 2 }}
      >
        <p sx={{ fontSize: 3, display: 'inline', p: 1 }}>
          We use cookies to track usage and make improvements
        </p>
        <Button size="small" color="default" onClick={() => setConsent(true)}>
          Works for me 👍
        </Button>
      </div>
    </SpringUp>
  ) : null
}

export default CookieConsentBanner
