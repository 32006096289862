/** @jsx jsx */

import { jsx } from 'theme-ui'
import { CreateEvent } from 'routes'
import { Add } from '@material-ui/icons'
import { stringify } from 'query-string'
import { FC } from 'react'
import { useFeatureFlag } from 'data/hooks'
import { EventCategory } from 'data/event'
import { PanelCard, LinkButton, PanelCardTitle } from './Elements'
import { PushLink } from './Navigation'

interface OrganiseContext {
  constituency?: string
  constituencyName?: string
  postcode?: string
}

export const AddButton: ViewElement<
  GetComponentProps<typeof PanelCard> & { context?: OrganiseContext }
> = ({
  context: { constituency, constituencyName, postcode } = {},
  ...props
}) => {
  const allowChatGroup = useFeatureFlag('createChatGroup')
  return (
    <PanelCard sx={{ variant: 'rows', alignItems: 'flex-start' }} {...props}>
      <PanelCardTitle>Are you an organiser?</PanelCardTitle>
      <LinkButton
        route={CreateEvent}
        to={`/create-event`}
        startIcon={<Add />}
        color="primary"
      >
        Add your event to the map 🗓
      </LinkButton>
      {constituency && (
        <LinkButton
          route={CreateEvent}
          to={{
            pathname: `/group/create`,
            search: `?${stringify({ constituency })}`
          }}
          startIcon={<Add />}
          color="primary"
        >
          Add chat group 💬
        </LinkButton>
      )}
    </PanelCard>
  )
}

const createTravelToHref = (props: {
  constituency: string
  postcode: string
}) => ({
  pathname: '/create-event',
  search: `?${stringify({
    type: EventCategory.TRAVELTOGETHER,
    constituency: props.constituency
  })}`
})

export const TravelToLink: FC<{
  constituency: string
  postcode: string
}> = props => (
  <PushLink
    sx={{ color: 'primary' }}
    to={createTravelToHref(props)}
    route={CreateEvent}
  >
    {props.children}
  </PushLink>
)
