/** @jsx jsx */

import { jsx } from 'theme-ui'
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useHistory } from 'react-router'
import { css } from '@emotion/core'

export interface BreadCrumb {
  to: string
  text: string
}
export interface BreadCrumbsBarProps {
  breadCrumbs: BreadCrumb[]
}
const BreadCrumbsBar: React.FC<BreadCrumbsBarProps> = props => {
  const { breadCrumbs } = props

  const listEl = useRef(null)
  useEffect(() => {
    if (listEl && listEl.current) {
      listEl.current.scrollTo(10000, 0)
    }
  }, [listEl, breadCrumbs])
  return (
    <div
      sx={{
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        maxWidth: '100%'
      }}
    >
      <div
        ref={listEl}
        sx={{
          maxWidth: '100%',
          overflowX: 'scroll',
          display: 'flex',
          whiteSpace: 'nowrap',
          paddingLeft: '10px',
          paddingRight: '10px',
          height: '100%',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
        css={css`
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */
          ::-webkit-scrollbar {
            height: 0px;
            background: transparent;
          }
        `}
      >
        {breadCrumbs.map((crumb, i) => {
          const { to, text } = crumb
          const isNotFirst = i !== 0
          const isLast = i === breadCrumbs.length - 1

          return (
            <React.Fragment key={i}>
              {isNotFirst && (
                <span
                  sx={{
                    opacity: isLast ? 1 : 0.8,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <KeyboardArrowRight />
                </span>
              )}
              <Link
                to={to}
                sx={{
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                <div sx={{ flex: 1 }}>
                  <span
                    sx={{
                      fontSize: 5,
                      fontWeight: 600,
                      opacity: isLast ? 1 : 0.8,
                      whiteSpace: 'nowrap',
                      paddingRight: isLast ? '20px' : 0
                    }}
                  >
                    {text}
                  </span>
                </div>
              </Link>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default BreadCrumbsBar
