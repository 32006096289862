/** @jsx jsx */
import { jsx } from 'theme-ui'
import gql from 'graphql-tag'
import React, { Fragment } from 'react'
import ElectionMarginChart from './ConstituencyVoteChart'
import {
  Panel,
  PanelCard,
  LoadingArea,
  PanelHeader,
  PanelSectionTitle,
  PanelCardTitle
} from './Elements'
import { EventList } from '../event/EventRecommendationList'
import { CommunicationGroupRecommendationsList } from './CommunicationGroupRecommendationsList'
import { ConstituencyPollingDayView } from '../views/__graphql__/ConstituencyPollingDayView'
import { useParams } from 'react-router'
import Collapser from './Collapser'
import { theme } from '../data/theme'
import Helmet from 'react-helmet'
import { CommunicationGroupRecommendation } from './__graphql__/CommunicationGroupRecommendation'
import { EventCategoryFragment } from '../event/EventFragments'

export const expanderSx = {
  textAlign: 'center',
  padding: 10,
  fontWeight: 'bold',
  bg: theme.colors.red[0],
  color: theme.colors.red[9],
  ':hover': {
    bg: theme.colors.red[1]
  },
  cursor: 'pointer'
}

const ConstituencyPollingDayGuide: ComponentWithFragment<
  ConstituencyPollingDayView,
  { loading?: boolean; postcode?: string }
> = ({ postcode, loading, constituency, constituencyTravelToEvents }) => {
  const params: { id?: string } = useParams()
  const { id } = params

  if (loading) {
    return (
      <Panel>
        <PanelHeader
          breadCrumbs={[
            { to: '/', text: 'Home' },
            ...(postcode
              ? [{ to: `/recommendations/${postcode}`, text: 'Search Results' }]
              : [])
          ]}
        />
        <LoadingArea>Finding information about the constituency</LoadingArea>
      </Panel>
    )
  }

  if (!constituency) {
    return null
  }

  const {
    campaignCentres,
    upcomingEvents,
    lastElection,
    name,
    groups
  } = constituency
  const otherEvents = upcomingEvents.filter(
    e =>
      e &&
      e.properties &&
      e.properties.labourCategory !== POLLING_DAY_CATEGORY_NAME
  )

  const isPollingDay = (g: CommunicationGroupRecommendation) => {
    return g.name.toLowerCase().includes('committee') ||
      g.name.toLowerCase().includes('centre') ||
      g.name.toLowerCase().includes('polling') ||
      g.name.toLowerCase().includes('gotv') ||
      g.name.toLowerCase().includes('g.o.t.v') ||
      g.name.toLowerCase().includes('vote') ||
      g.name.toLowerCase().includes('election')
      ? 1
      : 0
  }

  const pollingDayGroups = groups.slice().sort((a, b) => {
    return isPollingDay(b) - isPollingDay(a)
  })

  return (
    <React.Fragment>
      <Helmet>
        <title>{name} - My Campaign Map</title>
      </Helmet>
      <Panel>
        <PanelHeader
          breadCrumbs={[
            { to: '/', text: 'Home' },
            ...(postcode
              ? [{ to: `/recommendations/${postcode}`, text: 'Search Results' }]
              : []),
            { to: `/constituency/${id}`, text: name }
          ]}
        />

        <PanelSectionTitle bg="contrast.0">
          <h2 sx={{ variant: 'heading' }}>{name}</h2>
          {lastElection !== null && (
            <h2 sx={{ variant: 'heading', fontWeight: 400 }}>
              {Math.abs(lastElection.labourMarginality)} votes to win
            </h2>
          )}
        </PanelSectionTitle>

        {lastElection && (
          <PanelCard>
            <h4 sx={{ variant: 'subheading', mb: 1 }}>Last Election</h4>
            <ElectionMarginChart {...lastElection} />
          </PanelCard>
        )}

        <PanelCard sx={{ variant: 'rows', alignItems: 'flex-start' }}>
          <PanelCardTitle>New to campaigning?</PanelCardTitle>
          {/*<LinkButton
            route={routes.PollingDayGuide}
            to="/pollingdayguide"
            color="primary"
            variant="outlined"
          >
            Here's what to expect &rarr;
          </LinkButton>*/}

          <div sx={{ height: '1em' }} />

          {/* <PanelCardTitle>Want to up your canvassing game?</PanelCardTitle>
          <Button color="primary" variant="outlined">
            Key talking points &rarr;
          </Button> */}
        </PanelCard>

        {pollingDayGroups.length > 0 && (
          <Fragment>
            <PanelSectionTitle
              sx={{ fontSize: 2, py: 2 }}
              bg="contrast.0"
              sep="lightThick"
            >
              Say hello to everyone getting out the vote in {name}
            </PanelSectionTitle>

            <Collapser items={pollingDayGroups} collapsedCount={2}>
              {(items, toggle, text, canExpand) => (
                <Fragment>
                  <CommunicationGroupRecommendationsList groups={items} />
                  {canExpand && (
                    <div onClick={toggle} sx={expanderSx}>
                      {text}
                    </div>
                  )}
                </Fragment>
              )}
            </Collapser>
          </Fragment>
        )}

        {campaignCentres.length > 0 && (
          <Fragment>
            <PanelSectionTitle
              sx={{ fontSize: 2, py: 2 }}
              bg="contrast.0"
              sep="lightThick"
            >
              Where do I go?
            </PanelSectionTitle>

            <Collapser items={campaignCentres}>
              {(items, toggle, text, canExpand) => (
                <Fragment>
                  <EventList events={items} />
                  {canExpand && (
                    <div onClick={toggle} sx={expanderSx}>
                      {text}
                    </div>
                  )}
                </Fragment>
              )}
            </Collapser>
          </Fragment>
        )}

        {constituencyTravelToEvents.travelToEvents.length > 0 && (
          <Fragment>
            <PanelSectionTitle
              sx={{ fontSize: 2, py: 2 }}
              bg="contrast.0"
              sep="lightThick"
            >
              How can I get there?
            </PanelSectionTitle>

            <Collapser items={constituencyTravelToEvents.travelToEvents}>
              {(items, toggle, text, canExpand) => (
                <Fragment>
                  <EventList events={items} />
                  {canExpand && (
                    <div onClick={toggle} sx={expanderSx}>
                      {text}
                    </div>
                  )}
                </Fragment>
              )}
            </Collapser>
          </Fragment>
        )}

        {otherEvents.length > 0 && (
          <Fragment>
            <PanelSectionTitle
              sx={{ fontSize: 2, py: 2 }}
              bg="contrast.0"
              sep="lightThick"
            >
              What else can I do?
            </PanelSectionTitle>

            <Collapser items={otherEvents}>
              {(items, toggle, text, canExpand) => (
                <Fragment>
                  <EventList events={items} />
                  {canExpand && (
                    <div onClick={toggle} sx={expanderSx}>
                      {text}
                    </div>
                  )}
                </Fragment>
              )}
            </Collapser>
          </Fragment>
        )}
      </Panel>
    </React.Fragment>
  )
}

export const POLLING_DAY_CATEGORY_NAME = 'Polling Day Campaign Centre'

ConstituencyPollingDayGuide.fragment = gql`
  ${EventCategoryFragment}
  ${ElectionMarginChart.fragment}
  ${CommunicationGroupRecommendationsList.fragment}

  fragment ConstituencyPollingDayGuideFragment on ConstituencyType {
    id
    name
    campaignCentres {
      id
      properties {
        name
        startTime
        endTime
        labourCategory
        labourPriority
        ...EventCategoryFragment
      }
      geometry {
        type
        coordinates
      }
    }
    upcomingEvents {
      id
      properties {
        name
        startTime
        endTime
        ...EventCategoryFragment
        labourCategory
      }
      geometry {
        type
        coordinates
      }
    }
    lastElection {
      labourMarginality
      ...ElectionMarginChart
    }
    groups: communicationgroupSet {
      ...CommunicationGroupRecommendation
    }
  }
`

export default ConstituencyPollingDayGuide
