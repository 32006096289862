import { theme } from './theme'

export const getPartyColor = (x: string) => colorMap[x] || colorMap.default
export const isLabour = (x: string) =>
  x === 'Labour' || x === 'Labour and Co-operative'

const colorMap: Record<string, string> = {
  Labour: theme.colors.primary,
  'Labour and Co-operative': theme.colors.primary,
  Conservative: '#4E8AFF',
  'Liberal Democrat': '#FFCD1D',
  'Scottish National Party': '#E2DA0E',
  'Plaid Cymru': '#FFAD0D',
  Green: '#8ED24B',
  default: '#B5B5B5'
}

export const getNormalizedPartyName = (party: string) => {
  if (party === 'Labour and Co-operative') {
    return 'Labour'
  }

  return party
}
