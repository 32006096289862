import React, { useEffect } from 'react'

import { Button, IconButton } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import {
  WhatsApp,
  Facebook,
  House,
  Twitter,
  Language
} from '@material-ui/icons'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { useAnalytics, analytics } from 'data/analytics'
import { useAlert } from './Alert'

interface SocialGroupProps {
  onClose: () => void
  href: string
  name: string
}

export const useSocialGroup = (props: SocialGroupProps) => {
  const analytics = useAnalytics()

  return useAlert({
    title: `Join ${props.name} ?`,
    message: (
      <React.Fragment>
        <p>
          This is a volunteer run group where you can meet others in your area
          and find out how to help.
        </p>
        <p>
          It’s really important we volunteer safely. This means regularly
          washing your hands, following social distancing rules and not
          volunteering if you have symptoms.
        </p>
        <p>
          Remember, these groups are independent and we’re not responsible for
          their content.
        </p>
        <p>
          When you join make sure you say hi and let others know why you’re
          volunteering! 👋
        </p>
      </React.Fragment>
    ),
    confirmLabel: 'Join Group',
    onConfirm: () => {
      analytics.logEvent('join-group', { value: props.href })
      window.open(props.href)
      props.onClose()
    },
    onCancel: () => props.onClose()
  })
}

export const WhatsAppJoinShim = (props: SocialGroupProps) => {
  const [modal, showModal] = useSocialGroup(props)
  useEffect(() => {
    showModal()
  }, [])

  return modal
}

const iconReturn = (platform: string) => {
  switch (platform) {
    case 'Facebook Group':
      return <Facebook />
    case 'Nextdoor':
      return <House />
    case 'Whatsapp Broadcast':
      return <WhatsApp />
    case 'Whatsapp Chat':
      return <WhatsApp />
    case 'Web':
      return <Language />
    case 'Twitter':
      return <Twitter />
    default:
      return <React.Fragment />
      break
  }
}

export const WhatsAppJoinButton: ViewElement<
  {
    name: string
    href: string
    platform: string
    label?: string
    small?: boolean
  },
  ButtonProps
> = ({ name, href, label = 'Join WhatsApp Group', small, ...props }) => {
  const [confirmModal, showModal] = useSocialGroup({ name, href })
  return (
    <React.Fragment>
      {confirmModal}
      {!small && (
        <Button
          {...props}
          onClick={showModal}
          startIcon={iconReturn(props.platform)}
        >
          {label} <ArrowForwardIcon />
        </Button>
      )}
      {small && (
        <IconButton
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            showModal()
          }}
        >
          <WhatsApp />
        </IconButton>
      )}
    </React.Fragment>
  )
}
