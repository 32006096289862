/** @jsx jsx */
import { jsx } from 'theme-ui'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { formatRelative } from 'helpers/date'
import { EventCategoryFragment } from '../event/EventFragments'
// @ts-ignore

const catColor = (cat: string) => {
  switch (cat) {
    case 'SOCIALISING':
      return 'rgba(255, 187, 13, 0.6)'
    case 'TRAINING':
      return 'rgba(154, 106, 255, 0.6)'
    case 'MEETING':
    default:
      return 'rgba(255, 9, 82, 0.5)'
  }
}

// TODO: "Emphasis": [time, date, distance, location, region, type] - to help with different views
const EventCard: ComponentWithFragment<
  {
    verbose?: boolean
    compact?: boolean
  } & any
> = ({
  id,
  properties: {
    name,
    constituency: { name: constituency },
    startTime,
    description,
    amAttending,
    category: { name: category },
    geo: { europeanElectoralRegion }
  },
  topLine,
  verbose = false,
  compact = false,
  avatarWidth = 130,
  avatarHeight = 65
}) => (
  <Link
    to={`/event/${id}`}
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      display: 'block',
      my: [2, 3]
    }}
  >
    <div
      sx={{
        display: 'flex',
        flexDirection: compact && 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
      }}
    >
      <div
        sx={{
          width: avatarWidth,
          height: compact && avatarHeight,
          backgroundColor: catColor(category),
          borderRadius: 8,
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: 0
        }}
      >
        <div
          sx={{
            background: `url(https://imgur.com/gxPRjH9.png)`,
            backgroundSize: 'cover',
            opacity: 0.5,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
        <div
          sx={{
            color: 'white',
            fontSize: 2,
            fontWeight: 900,
            letterSpacing: '-0.02em',
            textAlign: 'center',
            zIndex: 1,
            width: '100%'
          }}
        >
          {category}
        </div>
      </div>
      <div
        sx={{
          width: '100%',
          pl: !compact && 2,
          overflow: 'hidden',
          textAlign: 'left'
        }}
      >
        {/* Date line */}
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1
          }}
        >
          <span
            sx={{
              color: 'primary',
              fontSize: 3,
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
          >
            {formatRelative(new Date(startTime))}
          </span>
          {amAttending && (
            <div
              sx={{
                bg: 'red.0',
                color: 'primary',
                fontWeight: 700,
                py: 1,
                px: 2,
                ml: 1,
                display: 'inline-block',
                borderRadius: 5,
                fontSize: 3
              }}
            >
              I'm in ✅
            </div>
          )}
        </div>
        <div
          sx={{
            mb: 1,
            fontSize: 4,
            fontWeight: 700,
            lineHeight: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {name}
        </div>
        <div sx={{ fontSize: 3 }}>
          <span sx={{ color: 'gray.9', fontWeight: 500 }}>{constituency}</span>
          <span sx={{ color: 'gray.7', px: 1 }}>&middot;</span>
          <span sx={{ color: 'gray.7' }}>{europeanElectoralRegion}</span>
        </div>
        {!!topLine && topLine}
      </div>
    </div>
    {verbose && (
      <p sx={{ color: 'gray.9', fontSize: 3, ml: avatarWidth }}>
        {description.slice(0, 120)}
      </p>
    )}
  </Link>
)

EventCard.fragment = gql`
  ${EventCategoryFragment}

  fragment EventCard on EventType {
    id
    properties {
      startTime
      address
      postcode
      name
      description
      ...EventCategoryFragment
      attendance
      amAttending
      constituency {
        id
        name
      }
      geo {
        europeanElectoralRegion
      }
    }
    geometry {
      type
      coordinates
    }
  }
`

export default EventCard
