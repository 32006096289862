/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import EventCard from 'event/EventCard'
import { useCurrentUser, useAuthenticatedCallback } from 'data/hooks'
import { SpringUp } from 'components/SpringUp'
import { NoScrollbar } from 'components/Pane'
import ordinal from 'ordinal'
import { upcomingEventsFilter } from 'data/selectors'
import { Button, CircularProgress, Dialog } from '@material-ui/core'
import { tuple } from 'helpers/array'
import { ImagePanelHeaderCard, PanelCard } from 'components/Elements'
import { usePushRoute } from 'components/Navigation'
import { commitToEvent } from 'data/sync'
import {
  EventCommitment,
  EventCommitmentVariables,
  EventCommitment_event
} from './__graphql__/EventCommitment'

const QUERY_EVENT = gql`
  ${EventCard.fragment}

  query EventCommitment($id: ID!) {
    event(id: $id) {
      ...EventCard
    }
    currentUser {
      firstName
      commitments {
        ...EventCard
      }
    }
  }
`

const eventConstituencyId = (e: EventCommitment_event) =>
  e.properties && e.properties.constituency && e.properties.constituency.id

export const useMakeCommitment = () => {
  const [callbackAfterAuthentication, authModal] = useAuthenticatedCallback()
  const [visibleId, setVisibleId] = React.useState<string>()

  const makeCommitment = (props: { eventId: string }) => {
    callbackAfterAuthentication({
      mode: 'register',
      onAuthenticated: async () => {
        await commitToEvent(props.eventId)
        setVisibleId(props.eventId)
      }
    })
  }

  const { data } = useQuery<EventCommitment, EventCommitmentVariables>(
    QUERY_EVENT,
    {
      skip: !visibleId,
      variables: {
        id: visibleId!
      }
    }
  )

  const { currentUser, event } = data || {}

  const commitmentHistory = (currentUser && currentUser.commitments) || []
  const firstTime = commitmentHistory.length === 1
  const eventsInConstituency = commitmentHistory.filter(
    e => eventConstituencyId(e) === eventConstituencyId(event)
  )
  const firstTimeInConstituency = eventsInConstituency.length === 1
  const upcomingEvents = commitmentHistory.filter(upcomingEventsFilter)
  const busy = upcomingEvents.length > 3

  const fragment = (
    <React.Fragment>
      {authModal}
      {data && (
        <Commitment
          {...data}
          open={!!visibleId}
          busy={busy}
          first={firstTime}
          firstTimeInConstituency={firstTimeInConstituency}
          close={() => setVisibleId(undefined)}
        />
      )}
    </React.Fragment>
  )

  return tuple(makeCommitment, fragment)
}

export const Commitment: React.FC<
  {
    open: boolean
    first?: boolean
    firstTimeInConstituency?: boolean
    busy?: boolean
    close?: () => void
  } & EventCommitment
> = ({
  open,
  close,
  event,
  first,
  busy,
  firstTimeInConstituency,
  currentUser
}) => {
  if (!event || !currentUser) {
    return null
  }

  const { constituency } = event.properties || {}
  const upcomingEvents = currentUser.commitments.filter(upcomingEventsFilter)

  return (
    <Dialog open={open}>
      <ImagePanelHeaderCard image="https://imgur.com/wl8bA1J.gif">
        {(firstTimeInConstituency &&
          constituency &&
          `${constituency.name} says cheers! 🙌`) ||
          (busy && 'You’re amazing 🤩') ||
          'Right on! ✊'}
      </ImagePanelHeaderCard>

      <PanelCard>
        {`${currentUser.firstName}, `}
        {(firstTimeInConstituency &&
          'this is your first commitment in the constituency.') ||
          (busy && (
            <React.Fragment>
              this is your <b>{ordinal(upcomingEvents.length)}</b> commitment.
              It’s regular campaigning like yours that builds the movement.
            </React.Fragment>
          )) ||
          'by campaigning on the streets you’re putting your politics into practice.'}
      </PanelCard>

      <PanelCard>
        <Button variant="contained" color="primary" onClick={close}>
          Alright!
        </Button>
      </PanelCard>
    </Dialog>
  )
}
