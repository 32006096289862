import { startCase } from 'lodash'

// deprecated, this should come from server
export enum EventCategory {
  CAMPAIGNING = 'Campaigning',
  MEETING = 'Meeting',
  OTHER = 'Other',
  SOCIALISING = 'Socialising',
  STRIKE = 'Strike',
  TRAINING = 'Training',
  TRAVELTOGETHER = 'TravelTogether'
}

export interface CreateEventFormFields {
  name: string
  description: string
  startTime: string | null
  constituency: string
  categoryGroup?: string
  address: {
    address: string
  }
  targetEventId?: string
  category: string
  subcategory?: string
  whatsappLink?: string
  contactNumber: string
  contactEmail: string
  isContactable: boolean
}

export const getCategory = (properties: {
  category: any
  subcategory?: any
}) => {
  if (properties.subcategory) {
    return properties.subcategory
  }
  return properties.category
}
