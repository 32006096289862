import gql from 'graphql-tag'
import { get } from 'lodash'
import { client } from 'data/graphql'
import { patchLocalState, createUpdateTokenAction } from 'data/state'
import { upcomingCommitmentsFragment } from 'commitments/YourUpcomingEvents'
import { uuid4 } from '@sentry/utils'
import { JWT_STORAGE_KEY } from '../symbols'
import { analytics } from '../data/analytics'
import { notifyAuthChange } from '../data/hooks'
import {
  ResetPassword,
  ResetPasswordVariables
} from './__graphql__/ResetPassword'

export const setAuthorisedRequestProperties = ({ headers = {} }) => {
  const token = localStorage.getItem(JWT_STORAGE_KEY)
  return {
    headers: {
      ...headers,
      // Authorization format required by django-graphql-jwt
      // https://django-graphql-jwt.domake.io/en/latest/authentication.html#http-header
      authorization: token ? `JWT ${token}` : undefined
    }
  }
}

// /

const MUTATION_REGISTER = gql`
  ${upcomingCommitmentsFragment}

  mutation RegisterNewUser(
    $email: String!
    $phone: String!
    $password: String!
    $postcode: String!
    $firstName: String!
    $lastName: String!
    $isPublic: Boolean!
    $isContactable: Boolean!
    $tags: [String!]!
  ) {
    createUser(
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      postcode: $postcode
      password: $password
      isPublic: $isPublic
      isContactable: $isContactable
      tags: $tags
    ) {
      currentUser {
        id
        email
        firstName
        lastName
        postcode
        nationbuilderId
        ...UpcomingCommitments
      }
    }
  }
`

export const register = async (variables: {
  password: string
  email: string
  firstName: string
  lastName: string
}) => {
  const res = await client.mutate({
    mutation: MUTATION_REGISTER,
    variables
  })
  notifyAuthChange()
  return get(res, 'data.currentUser.id')
}

const QUERY_GET_TOKEN = gql`
  mutation EmailPasswordLogin($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`

export const authorise = async ({
  email,
  password
}: {
  password: string
  email: string
}) => {
  const res = await client.mutate<
    { token: string },
    { email: string; password: string }
  >({
    mutation: QUERY_GET_TOKEN,
    variables: { email, password }
  })
  const token = get(res, 'data.tokenAuth.token')
  if (token) {
    await patchLocalState(createUpdateTokenAction(token))
    const userQuery = await getCurrentUser()
    analytics.setUserId(email)
    return get(userQuery, 'data.currentUser')
  }

  notifyAuthChange()
  return false
}

const MUTATION_RESET = gql`
  mutation ResetPassword($input: PasswordResetMutationInput!) {
    resetPassword(input: $input) {
      email
    }
  }
`

export const resetPassword = async (email: string) => {
  const res = await client.mutate<ResetPassword, ResetPasswordVariables>({
    mutation: MUTATION_RESET,
    variables: { input: { email, mutationId: uuid4() } }
  })

  return !res.errors
}

const MUTATION_VERIFY = gql`
  mutation TokenLogin($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`

export const tokenIsValid = async (token: string) => {
  try {
    const data = await client.mutate({
      mutation: MUTATION_VERIFY,
      variables: { token }
    })
    return !!data.errors
  } catch (e) {
    return false
  }
}

const LOGGED_IN_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      id
      firstName
      postcode
      commitments {
        id
        properties {
          startTime
          amAttending
        }
      }
    }
  }
`

export const getCurrentUser = async () => {
  const data = await client.query({ query: LOGGED_IN_USER_QUERY })
  return data
}

// /

export const signout = () => {
  client.resetStore()
  notifyAuthChange()
}
