/** @jsx jsx */

import * as React from 'react'
import { Button } from '@material-ui/core'
import { jsx } from 'theme-ui'

import gql from 'graphql-tag'
import { CommunicationGroupRecommendation } from './__graphql__/CommunicationGroupRecommendation'

import { WhatsAppJoinButton } from './WhatsAppJoinButton'
import { PanelCardTitle, PanelCard } from './Elements'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

export const AddGroupButton: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Button
      sx={{
        ml: 1,
        float: 'right',
        flexShrink: 0,
        height: 'max-content'
      }}
      variant="outlined"
      color="secondary"
      href="https://airtable.com/shr60xqjMBszOCgIt"
    >
      {text} <ArrowForwardIcon />
    </Button>
  )
}

const ShowCommunicationGroups: React.FC<{
  groups: CommunicationGroupRecommendation[]
  amtGroups: number
}> = ({ groups, amtGroups }) => {
  const groupSlice = () => {
    if (groups.length < amtGroups) {
      return groups
    } else {
      return groups.slice(0, amtGroups)
    }
  }

  return (
    <div>
      <PanelCard
        sep
        sx={{
          bg: 'primary',
          borderBottom: '1px solid grey',
          borderBottomColor: 'gray.2'
        }}
      >
        <PanelCardTitle
          sx={{
            color: 'white',
            variant: 'columns',
            alignItems: 'center',
            mb: 0
          }}
        >
          <span sx={{ fontSize: [4, 5] }}>Nearby action groups</span>
        </PanelCardTitle>
      </PanelCard>
      {groupSlice().map(group => {
        const props = group.properties

        if (props === null) {
          return null
        } else {
          return (
            <PanelCard
              sep
              key={group.id}
              sx={{
                bg: 'white',
                borderBottom: '1px solid grey',
                borderBottomColor: 'gray.2',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <PanelCardTitle
                sx={{
                  color: 'black',
                  variant: 'columns',
                  alignItems: 'center',
                  mb: 0,
                  mr: 2,
                  flexShrink: 1
                }}
              >
                <span sx={{ fontSize: [3, 4] }}>{props.name}</span>
              </PanelCardTitle>

              <WhatsAppJoinButton
                sx={{
                  ml: 1,
                  float: 'right',
                  flexShrink: 0,
                  height: 'max-content'
                }}
                variant="outlined"
                color="primary"
                label="Join"
                name={props.name}
                href={props.joinLink}
                platform={props.platform.name}
              />
            </PanelCard>
          )
        }
      })}
      <PanelCard sep sx={{}}>
        <PanelCardTitle
          sx={{
            color: 'grey',
            mb: 0
          }}
        >
          <span sx={{ fontSize: [3, 4] }}> No group near you?</span>
        </PanelCardTitle>
      </PanelCard>
      <PanelCard sx={{ pb: [2, 1] }}>
        <span>
          If there isn't an action group in your area, we need you to{' '}
          <strong>
            step up, become a lead organiser, and start building one.
          </strong>{' '}
          Fill in <a href="https://airtable.com/shr60xqjMBszOCgIt">this form</a>{' '}
          and we'll be in touch about the next steps very soon
        </span>
      </PanelCard>
      <PanelCard sx={{ pt: [2, 1] }}>
        <AddGroupButton text="Set up a group" />
      </PanelCard>
    </div>
  )
}

export const CommunicationGroupRecommendationsList: ComponentWithFragment<{
  groups: CommunicationGroupRecommendation[]
}> = ({ groups }) => {
  return <ShowCommunicationGroups groups={groups} amtGroups={3} />
}

CommunicationGroupRecommendationsList.fragment = gql`
  fragment CommunicationGroupRecommendation on CommunicationGroupType {
    id
    properties {
      name
      description
      joinLink
      published
      official
      platform {
        id
        icon
        name
        description
      }
      tags {
        id
        icon
        name
        description
      }
    }
  }
`
