import queryString from 'query-string'
import { tuple } from 'helpers/array'

export const convertPostcodeToCoordinates = async (
  postcode: string
): Promise<[number, number]> => {
  const res = await fetch(`https://api.postcodes.io/postcodes/${postcode}`)
  if (!res.ok || res.status !== 200) throw new Error('Failed to get postcode')
  const data = await res.json()
  if (data.status !== 200 || !data.result) {
    throw new Error('Failed to validate postcode')
  }
  return [data.result.latitude, data.result.longitude].reverse()
}

// TODO(postelection): remove pasted code from above
export const convertPostcodeToConstituency = async (
  postcode: string
): Promise<string> => {
  const res = await fetch(`https://api.postcodes.io/postcodes/${postcode}`)
  if (!res.ok || res.status !== 200) throw new Error('Failed to get postcode')
  const data = await res.json()
  if (data.status !== 200 || !data.result) {
    throw new Error('Failed to validate postcode')
  }
  return data.result.parliamentary_constituency
}

export const convertCoordinatesToPostcode = async ({
  longitude,
  latitude
}: Coordinates): Promise<string> => {
  const res = await fetch(
    `https://api.postcodes.io/postcodes?lon=${longitude}&lat=${latitude}&radius=1000m&limit=1`
  )
  if (!res.ok || res.status !== 200) throw new Error('Failed to get postcode')
  const data = await res.json()
  if (
    data.status !== 200 ||
    !data.result ||
    !Array.isArray(data.result) ||
    data.result.length === 0
  )
    throw new Error('Failed to validate postcode')
  return data.result[0].postcode
}

export const convertLocationToCoordinates = async (
  location: string
): Promise<[number, number]> => {
  const encodedLocation = encodeURIComponent(location)
  const res = await fetch(
    `https://nominatim.openstreetmap.com/search?q=${encodedLocation}&format=geojson&countrycodes=gb&limit=1&email=digital@peoplesmomentum.com`
  )
  const data = await res.json()
  if (data.features.length === 0)
    throw new Error('Failed to find co-ordinates for location')
  return data.features[0].geometry.coordinates
}

export type Geocode = {
  address: string
  postcode: string
  coordinates: { latitude: number; longitude: number }
}

export const geoDetails = async (search: string): Promise<Geocode> => {
  const args = queryString.stringify({
    address: search,
    components: 'country:gb',
    key: process.env.GOOGLE_MAPS_API_KEY
  })
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?${args}`
  )
  const data: Google.GeocodeResults = await res.json()
  return data.results
    .filter(p =>
      p.address_components.find(i => i.types.includes('postal_code'))
    )
    .map(p => ({
      address: p.formatted_address,
      postcode: p.address_components.find(i => i.types.includes('postal_code'))!
        .short_name,
      coordinates: {
        latitude: p.geometry.location.lat,
        longitude: p.geometry.location.lng
      }
    }))[0]
}

export type Autocomplete = { address: string }

export const geoAutocomplete = async (
  search: string
): Promise<Autocomplete[]> => {
  try {
    const res = await fetch(`${process.env.API_HOST}/autocomplete/${search}`)
    const results: Google.AutoCompleteResults = await res.json()
    return results.predictions.map(p => ({ address: p.description }))
  } catch (e) {
    return []
  }
}

export const UKBounds = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Point',
    coordinates: tuple(-3.578428221023512, 54.72814098390994)
  }
}
