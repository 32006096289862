/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { Marker } from 'react-mapbox-gl'
import { Icon } from '../components/Elements'
import { CommunicationGroupRecommendation } from '../components/__graphql__/CommunicationGroupRecommendation'
import { useSocialGroup } from 'components/WhatsAppJoinButton'

const CommunicationGroupGeo: React.FC<
  CommunicationGroupRecommendation & {
    onClick?: React.MouseEventHandler
    pinOnly?: boolean
  }
> = React.memo(({ id, geometry, properties, onClick, pinOnly = false }) => {
  const [alert, join] = useSocialGroup({
    onClose: () => {},
    name: (properties && properties.name) || '',
    href: (properties && properties.joinLink) || ''
  })
  const offset = [0, 0]
  const platform = properties.platform
  return (
    <Marker
      key={id}
      coordinates={geometry.coordinates}
      offset={offset}
      anchor="bottom"
      onClick={() => {
        join()
      }}
    >
      {alert}
      <div
        sx={{
          textAlign: 'center',
          lineHeight: 1,
          width: 160,
          opacity: 1,
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        <div
          sx={{
            textAlign: 'center',
            lineHeight: 1,
            width: 160,
            opacity: 1,
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          <div
            sx={{
              textAlign: 'center',
              fontSize: 30
            }}
          >
            <Icon icon={platform.icon} />
          </div>
          {!pinOnly && (
            <div
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                variant: 'card',
                bg: 'white',
                color: 'black',
                p: 1,
                padding: '10px'
              }}
            >
              <div
                sx={{
                  textAlign: 'center',
                  fontSize: 3,
                  fontWeight: 600,
                  textTransform: 'capitalize'
                }}
              >
                {properties.name}
              </div>
              <div
                sx={{
                  mt: 1,
                  textAlign: 'center',
                  fontSize: 2,
                  color: 'primary',
                  textTransform: 'uppercase',
                  letterSpacing: 0.2,
                  fontWeight: 500
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </Marker>
  )
})

export default CommunicationGroupGeo
