/** @jsx jsx */

import { jsx } from 'theme-ui'
import gql from 'graphql-tag'
import { getPartyColor, isLabour } from 'data/parties'
import { orderBy, take } from 'lodash'
import {
  ElectionMarginChart,
  ElectionMarginChart_candidateSet
} from './__graphql__/ElectionMarginChart'

const barHeight = 14

/** 2-bar chart showing labour votes and competitor */
const ElectionMarginChartView: ComponentWithFragment<ElectionMarginChart> = ({
  className,
  turnout,
  candidateSet
}) => {
  const candidatesByVotes = orderBy(candidateSet, [c => c.votes], ['desc'])
  const labourOrChallenger = getLabourCandidateOrChallenger(candidatesByVotes)

  return (
    <div className={className} sx={{ position: 'relative' }}>
      {take(candidatesByVotes, 3).map(c => (
        <ElectionMarginBar
          key={c.party}
          turnout={turnout}
          {...c}
          sx={{ mb: '2px' }}
        />
      ))}
    </div>
  )
}

export const ElectionMarginChartEmbed: ViewElement<{
  election?: ElectionMarginChart | null
}> = ({ election, ...props }) => {
  if (!election) {
    return null
  }

  return (
    <div {...props}>
      <h3 sx={{ variant: 'subheading', mb: 1 }}>Last Election</h3>
      <ElectionMarginChartView {...election} />
    </div>
  )
}

ElectionMarginChartView.fragment = gql`
  fragment ElectionMarginChart on ElectionType {
    turnout
    candidateSet {
      party
      votes
    }
  }
`

const ElectionMarginBar: ViewElement<{
  votes: number
  turnout: number
  party: string
}> = ({ turnout, party, votes, className }) => (
  <div
    className={className}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      height: barHeight,
      alignItems: 'center'
    }}
  >
    <div
      sx={{
        bg: getPartyColor(party),
        width: `${(votes / turnout) * 100}%`,
        height: barHeight
      }}
    />
    <span sx={{ fontSize: 1, ml: 2, color: '#565656', fontWeight: 300 }}>
      {party} ({new Intl.NumberFormat('en-UK').format(votes)})
    </span>
  </div>
)

const getLabourCandidateOrChallenger = (
  candidates: ElectionMarginChart_candidateSet[]
) => {
  if (isLabour(candidates[0].party)) {
    return candidates[1]
  }

  return candidates.find(c => isLabour(c.party))
}

export default ElectionMarginChartView
