import gql from 'fraql'
import { client } from 'data/graphql'
import { patchLocalState, createUpdatePostcodeAction } from 'data/state'
import EventCard from 'event/EventCard'
import { upcomingCommitmentsFragment } from 'commitments/YourUpcomingEvents'

const MUTATION_UPDATE_POSTCODE = gql`
  mutation UpdatePostcode($postcode: String!) {
    updateUser(postcode: $postcode) {
      currentUser {
        id
        postcode
      }
    }
  }
`

export const setPostcode = async (postcode: string) => {
  try {
    client.mutate({
      mutation: MUTATION_UPDATE_POSTCODE,
      variables: { postcode }
    })
  } catch (e) {}
  return patchLocalState(createUpdatePostcodeAction(postcode))
}

const MUTATION_RSVP = gql`
  ${upcomingCommitmentsFragment}

  mutation RSVP($eventId: ID!) {
    commit(eventId: $eventId) {
      currentUser {
        id
        ...UpcomingCommitments
        commitments {
          id
          properties {
            amAttending
            attendance
          }
        }
      }
      # update the 'amAttending' property on this event, for the cache
      event {
        id
        properties {
          amAttending
          attendance
        }
      }
    }
  }
`

export const commitToEvent = async (eventId: string) => {
  await client.mutate({
    mutation: MUTATION_RSVP,
    variables: { eventId }
  })
}

// /

const MUTATION_CANCEL_RSVP = gql`
  ${upcomingCommitmentsFragment}

  mutation CancelRSVP($eventId: ID!) {
    uncommit(eventId: $eventId) {
      currentUser {
        id
        ...UpcomingCommitments
        commitments {
          id
          properties {
            amAttending
            attendance
          }
        }
      }
      # update the 'amAttending' property on this event, for the cache
      event {
        id
        properties {
          amAttending
          attendance
        }
      }
    }
  }
`

export const cancelCommitmentToEvent = async (eventId: string) => {
  await client.mutate({
    mutation: MUTATION_CANCEL_RSVP,
    variables: { eventId }
  })
}
