/* eslint-disable @typescript-eslint/no-non-null-assertion */
import gql from 'graphql-tag'
import ConstituencyDashboardView from 'components/ConstituencyDashboard'
import { geometryFragment } from 'helpers/geo'
import EventDetail from 'event/EventDetail'
import { EventRecommendationsList } from 'event/EventRecommendationList'
import { startOfDay, addWeeks, subHours, addMinutes, addDays } from 'date-fns'
import { useQuery } from '@apollo/react-hooks'
import { useMemo } from 'react'
import { QueryResult } from '@apollo/react-common'
import { CommunicationGroupRecommendationsList } from '../components/CommunicationGroupRecommendationsList'
import {
  RecommendationView,
  RecommendationViewVariables
} from './__graphql__/RecommendationView'
import {
  ConstituencyView,
  ConstituencyViewVariables
} from './__graphql__/ConstituencyView'

import { EventVariables } from './__graphql__/Event'
import { EventsView, EventsViewVariables } from './__graphql__/EventsView'
import {
  CategorisedEventsView,
  CategorisedEventsViewVariables
} from './__graphql__/CategorisedEventsView'
import {
  CategoryView,
  CategoryView_categories
} from './__graphql__/CategoryView'

/* Screen-level queries all live here so that they can be imported by pages that need to preload
 * the data within them without importing the screen components directly.
 */

export const QUERY_CREATE_EVENTS_SCREEN = gql`
  query CategoryView {
    categories {
      id
      name
      emoji
      description
      userVisible
      allowUserCreate
      subcategories {
        id
        name
        emoji
        description
        userVisible
        allowUserCreate
      }
    }
  }
`

export const useCategories = () => {
  return tryError(useQuery<CategoryView>(QUERY_CREATE_EVENTS_SCREEN))
}

export type CatOrSubcat = {
  level: string // category or subcategory
  id: string
  name: string
  emoji: string
  description: string
  userVisible: boolean
  allowUserCreate: boolean
}

export const flattenSubcategories = (
  categories: CategoryView_categories[]
): CatOrSubcat[] => {
  let subcats: CatOrSubcat[] = []
  for (const graphql_cat of categories) {
    if (graphql_cat.subcategories.length > 0) {
      for (const graphql_subcat of graphql_cat.subcategories) {
        subcats.push({
          level: 'subcategory',
          ...graphql_subcat
        })
      }
    } else {
      subcats.push({
        level: 'category',
        ...graphql_cat
      })
    }
  }
  return subcats.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    } else if (a.name === b.name) {
      return 0
    } else {
      return 1
    }
  })
}

export const QUERY_RECOMMENDATIONS_SCREEN = gql`
  ${CommunicationGroupRecommendationsList.fragment}
  ${EventRecommendationsList.fragment}
  ${geometryFragment}

  query RecommendationView(
    $postcode: String!
    $startDate: String!
    $endDate: String!
  ) {
    groups: groupsForPostcode(postcode: $postcode) {
      ...CommunicationGroupRecommendation
      geometry {
        ...GeoJSON
      }
    }
    events: eventsForPostcode(
      postcode: $postcode
      dateStart: $startDate
      dateEnd: $endDate
    ) {
      ...EventRecommendation
      properties {
        address
        postcode
        description
        straightLineDistance(postcode: $postcode)
      }
      geometry {
        ...GeoJSON
      }
    }
  }
`

export const usePostcodeRecommendations = (postcode: string | undefined) => {
  const startDate = useMemo(() => subHours(new Date(), 1.5), [])
  const endDate = useMemo(() => addWeeks(startDate, 4), [startDate])

  return tryError(
    useQuery<RecommendationView, RecommendationViewVariables>(
      QUERY_RECOMMENDATIONS_SCREEN,
      {
        skip: !postcode,
        variables: {
          postcode: postcode!,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      }
    )
  )
}

export const QUERY_CONSTITUENCY_SCREEN = gql`
  ${ConstituencyDashboardView.fragment}

  query ConstituencyView(
    $id: String!
    $postcode: String!
    $usePostcode: Boolean!
  ) {
    constituency(id: $id) {
      id
      ...ConstituencyDashboard
    }
    constituencyTravelToEvents(constituencyId: $id, postcode: $postcode)
      @include(if: $usePostcode) {
      travelToEvents {
        id
        properties {
          name
          startTime
          address
          targetEvent {
            id
          }
        }
      }
    }
  }
`

export const useConstituencyScreenData = (
  id: string | undefined,
  postcode?: string
) =>
  tryError(
    useQuery<ConstituencyView, ConstituencyViewVariables>(
      QUERY_CONSTITUENCY_SCREEN,
      {
        skip: !id,
        variables: {
          id: id!,
          postcode: postcode || '',
          usePostcode: !!postcode
        }
      }
    )
  )

export const QUERY_EVENT_SCREEN = gql`
  ${geometryFragment}
  ${EventDetail.fragment}

  query Event($id: ID!) {
    event(id: $id) {
      ...EventDetail
      geometry {
        ...GeoJSON
      }
    }
  }
`

export const useEvent = (id: string | undefined) =>
  tryError(
    useQuery<Event, EventVariables>(QUERY_EVENT_SCREEN, {
      skip: !id,
      variables: {
        id: id!
      }
    })
  )

const tryError = <T>(res: QueryResult<T>) => {
  if (res.error) {
    throw res.error
  }

  return res
}

export const QUERY_EVENTS_SCREEN = gql`
  ${EventRecommendationsList.fragment}
  ${geometryFragment}

  query EventsView(
    $postcode: String!
    $startDate: String!
    $endDate: String!
    $searchRadius: Float
  ) {
    events: eventsNearPostcode(
      postcode: $postcode
      dateStart: $startDate
      dateEnd: $endDate
      searchRadius: $searchRadius
    ) {
      ...EventRecommendation
      properties {
        address
        postcode
        description
        straightLineDistance(postcode: $postcode)
      }
      geometry {
        ...GeoJSON
      }
    }
  }
`

export const useAllEvents = (
  postcode: string | undefined,
  dateParam = '',
  searchRadius?: number
) => {
  const dateParsed = Date.parse(dateParam)
  const date = isNaN(dateParsed) ? new Date() : new Date(dateParsed)
  const startDate = startOfDay(date)
  const endDate = addDays(addMinutes(startDate, -1), 1)

  return tryError(
    useQuery<EventsView, EventsViewVariables>(QUERY_EVENTS_SCREEN, {
      skip: !postcode,
      variables: {
        postcode: postcode!,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        searchRadius: searchRadius || 20
      }
    })
  )
}

export const QUERY_CATEGORISED_EVENTS_SCREEN = gql`
  ${EventRecommendationsList.fragment}
  ${geometryFragment}

  query CategorisedEventsView(
    $category: String!
    $startDate: String!
    $endDate: String!
  ) {
    events: eventsByCategory(
      category: $category
      dateFrom: $startDate
      dateTo: $endDate
    ) {
      ...EventRecommendation
      properties {
        address
        postcode
        description
      }
      geometry {
        ...GeoJSON
      }
    }
  }
`

export const useCategorisedEvents = (
  category: string,
  dateParam = '',
  daysAhead = 7
) => {
  const dateParsed = Date.parse(dateParam)
  const date = isNaN(dateParsed) ? new Date() : new Date(dateParsed)
  const startDate = startOfDay(date)
  const endDate = addDays(addMinutes(startDate, -1), daysAhead)

  return tryError(
    useQuery<CategorisedEventsView, CategorisedEventsViewVariables>(
      QUERY_CATEGORISED_EVENTS_SCREEN,
      {
        skip: !category,
        variables: {
          category,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      }
    )
  )
}
