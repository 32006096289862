import { client } from 'data/graphql'
import { JWT_STORAGE_KEY } from 'symbols'

type ActionUpdatePostcode = {
  type: 'ActionUpdatePostcode'
  postcode: string
}

type ActionUpdateToken = {
  type: 'ActionUpdateToken'
  token: string
}

type ActionSignOut = {
  type: 'ActionSignOut'
}

type Actions = ActionUpdatePostcode | ActionUpdateToken | ActionSignOut

// /

export const createUpdatePostcodeAction = (
  postcode: string
): ActionUpdatePostcode => ({
  type: 'ActionUpdatePostcode',
  postcode
})

export const createUpdateTokenAction = (token: string): ActionUpdateToken => ({
  type: 'ActionUpdateToken',
  token
})

export const createSignOutAction = (): ActionSignOut => ({
  type: 'ActionSignOut'
})

// /

export const patchLocalState = async (action: Actions): Promise<void> => {
  switch (action.type) {
    case 'ActionUpdatePostcode':
      await client.writeData({ data: { postcode: action.postcode } })
      return
    case 'ActionUpdateToken':
      var { token } = action
      localStorage.setItem(JWT_STORAGE_KEY, token)
      await client.writeData({ data: { token } })
      return
    case 'ActionSignOut':
      localStorage.removeItem(JWT_STORAGE_KEY)
      await client.writeData({ data: { token: null } })
  }
}
