/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { Marker } from 'react-mapbox-gl'
import { DateTimeFormat } from 'helpers/date'
import { DateTimeValue } from 'components/DateField'
import { EventDetail } from '../components/__graphql__/EventDetail'

const EventGeo: React.FC<
  EventDetail & {
    onClick?: React.MouseEventHandler
    pinOnly?: boolean
  }
> = React.memo(
  ({ id, geometry, properties, onClick, pinOnly = false, exact = false }) => {
    const offset = [0, 0]

    const category = properties.subcategory || properties.category
    return (
      <Marker
        key={id}
        coordinates={geometry.coordinates}
        offset={offset}
        anchor="bottom"
        onClick={onClick}
      >
        <div
          sx={{
            textAlign: 'center',
            lineHeight: 1,
            width: 140,
            opacity: properties!.amAttending ? 0.75 : 1,
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          <div
            sx={{
              textAlign: 'center',
              fontSize: properties!.amAttending ? 20 : 30
            }}
          >
            {properties!.labourPriority === 3 || category.emoji}
            {properties!.amAttending && '✅'}
          </div>
          {!pinOnly && (
            <div
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                variant: 'card',
                bg: 'white',
                color: 'black',
                p: 1
              }}
            >
              <div
                sx={{
                  textAlign: 'center',
                  fontSize: 3,
                  fontWeight: 600,
                  textTransform: 'capitalize'
                }}
              >
                {category.name}
              </div>
              <div
                sx={{
                  mt: 1,
                  textAlign: 'center',
                  fontSize: 2,
                  color: 'primary',
                  textTransform: 'uppercase',
                  letterSpacing: 0.2,
                  fontWeight: 500
                }}
              >
                <DateTimeValue
                  value={properties!.startTime}
                  format={DateTimeFormat.DAY_LONG}
                  relative
                />
              </div>
            </div>
          )}
        </div>
      </Marker>
    )
  }
)

export default EventGeo
