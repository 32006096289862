import * as React from 'react'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'
import { createHttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { setContext } from 'apollo-link-context'
import { setAuthorisedRequestProperties } from 'auth/authHelpers'
import { ApolloLink } from 'apollo-link'
import { JWT_STORAGE_KEY } from 'symbols'

const cache = new InMemoryCache()

const initialCacheState = {
  data: {
    token: localStorage.getItem(JWT_STORAGE_KEY),
    postcode: null
  }
}

const GRAPHQL_ENDPOINT = `${process.env.API_HOST}/graphql` // process.env.process.env
const httpLink = createHttpLink({ uri: GRAPHQL_ENDPOINT })
const retryLink = new RetryLink()

const authLink = setContext((_, request) => {
  return {
    ...setAuthorisedRequestProperties(request),
    uri: GRAPHQL_ENDPOINT
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([retryLink, authLink, httpLink]),
  defaultOptions: {
    // Docs: https://www.apollographql.com/docs/react/features/error-handling/#error-policies
    query: {
      errorPolicy: 'all' // Prefer to display data where possible
    }
  },
  // @ts-ignore
  // uri: process.env.GRAPHQL_ENDPOINT,
  // @ts-ignore
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache
})

cache.writeData(initialCacheState)

client.onResetStore(() => {
  localStorage.removeItem(JWT_STORAGE_KEY)
  return Promise.resolve(cache.writeData(initialCacheState))
})

const GraphQLProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphQLProvider
