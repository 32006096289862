/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { RegisterForm, RegistrationParams } from 'auth/RegisterForm'
import { SignInForm } from 'auth/SignInForm'
import { Panel } from 'components/Elements'
import { useAnalytics } from 'data/analytics'
import { PasswordResetForm } from './PasswordResetForm'

export type AuthMode = 'signin' | 'register' | 'reset'

const AuthModal: React.FC<{
  open: boolean
  mode?: AuthMode
  onAuthentication: () => void
  onClose?: () => void
  setMode: (x: AuthMode) => void
  initialValues?: RegistrationParams
}> = ({
  open,
  onClose,
  mode,
  setMode,
  initialValues,
  onAuthentication,
  children
}) => {
  const analytics = useAnalytics()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (open) {
      analytics.logEvent('auth-start')
    }
  }, [open])

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6">
            {mode === 'signin' && 'Sign In'}
            {mode === 'register' && 'Register'}
            {mode === 'reset' && 'Reset Password'}
          </Typography>
        </Toolbar>
      </AppBar>

      <Panel noFooter sx={{ mt: fullScreen ? '64px' : 0 }}>
        {children || (
          <React.Fragment>
            {mode === 'signin' && (
              <SignInForm
                onAuthentication={() => {
                  analytics.logEvent('sign-in-complete')
                  onAuthentication()
                }}
                onRequestRegistration={() => setMode('register')}
              />
            )}
            {mode === 'register' && (
              <RegisterForm
                onAuthentication={onAuthentication}
                onRequestSignIn={() => {
                  analytics.logEvent('register-complete')
                  setMode('signin')
                }}
                initialValues={initialValues}
              />
            )}
            {mode === 'reset' && <PasswordResetForm onCompleted={onClose} />}
          </React.Fragment>
        )}
      </Panel>
    </Dialog>
  )
}

export default AuthModal
