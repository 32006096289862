/** @jsx jsx */
import { jsx } from 'theme-ui'
import gql from 'graphql-tag'
import { DateTimeFormat } from 'helpers/date'
import React from 'react'
import { Chip } from '@material-ui/core'
import { getCategory, EventCategory } from 'data/event'
import { useMakeCommitment } from 'views/Commitment'
import { CurrentUser_currentUser } from 'data/__graphql__/CurrentUser'
import {
  Panel,
  PanelCard,
  PanelCardTitle,
  Content,
  LoadingArea,
  PanelHeader,
  LinkButton,
  LinkButtonExternal
} from '../components/Elements'
import ElectionMarginChartView from '../components/ConstituencyVoteChart'
import { DateTimeValue } from '../components/DateField'
import { EventDetail } from './__graphql__/EventDetail'
import { WhatsAppJoinButton } from '../components/WhatsAppJoinButton'
import { usePostcode } from 'data/hooks'
import { EventCategoryFragment } from './EventFragments'

const EventDetailView: ComponentWithFragment<
  Partial<EventDetail>,
  {
    id: string
    loading?: boolean
    onRsvp: (id: string) => void
    currentUser?: CurrentUser_currentUser
  }
> = ({ id, loading, properties, currentUser }) => {
  const [makeCommitment, commitmentModal] = useMakeCommitment()

  const postcode = usePostcode()

  if (!properties) {
    return null
  }

  const mine = Boolean(
    currentUser &&
      properties &&
      properties.manager &&
      currentUser.id === properties.manager.id
  )
  const category = getCategory(properties)

  return (
    <Panel
      sx={{
        gridTemplateRows: 'repeat(4, min-content) 1fr'
      }}
    >
      {commitmentModal}
      <PanelHeader
        breadCrumbs={[
          { to: '/', text: 'Home' },
          {
            to: `/recommendations/${postcode}`,
            text: 'Search Results'
          },
          { to: `/event/${id}`, text: 'Event' }
        ]}
      />

      {(loading && (
        <LoadingArea>Getting information about this event</LoadingArea>
      )) || (
        <React.Fragment>
          <PanelCard sep>
            <PanelCardTitle sx={{ mb: 2 }}>
              <span sx={{ textTransform: 'capitalize' }}>{category.label}</span>
            </PanelCardTitle>
            <h2 sx={{ variant: 'heading' }}>
              {category.emoji} {properties.name}
            </h2>
          </PanelCard>

          {mine && (
            <PanelCard sep bg="white">
              <PanelCardTitle sx={{ mb: 2 }}>This is your event</PanelCardTitle>
              <div sx={{ variant: 'body' }}>
                <p>You are organising this event. Nice one! ✊</p>
                <p>
                  We’re busy building out this site and you can’t edit it yet.
                  If you need to change anything, talk to someone using the help
                  icon at the bottom of the screen.
                </p>
              </div>

              <div sx={{ mb: 3 }} />

              <Chip label={properties.published ? 'Published' : 'Pending'} />

              {!properties.published && (
                <div sx={{ variant: 'caption', mt: 1 }}>
                  We’re reviewing your event right now. We’ll let you know when
                  it’s live.
                </div>
              )}
            </PanelCard>
          )}

          {/* {!mine && (
            <PanelCard sep bg="white">
              <PanelCardTitle sx={{ mb: 2 }}>Attendance</PanelCardTitle>

              {!properties.amAttending ? (
                !properties.isFull ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => makeCommitment({ eventId: id })}
                  >
                    Tell local organisers you're coming
                  </Button>
                ) : (
                  <Button color="primary" variant="outlined" disabled>
                    Sorry, this event is full
                  </Button>
                )
              ) : (
                <div sx={{ variant: 'columns', alignItems: 'center' }}>
                  <div sx={{ flex: 1 }}>You're attending ✅</div>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => cancelCommitmentToEvent(id)}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </PanelCard>
          )} */}

          <BasicDetailsCard {...properties} />

          <PanelCard bg="white" sx={{ mt: 18 }}>
            <PanelCardTitle>
              {properties.category === EventCategory.TRAVELTOGETHER
                ? 'Travel Details'
                : 'Organiser’s Description'}
            </PanelCardTitle>
            <Content>{properties.description}</Content>
          </PanelCard>

          {properties.category === EventCategory.TRAVELTOGETHER &&
            properties.targetEvent && (
              <React.Fragment>
                <BasicDetailsCard {...properties.targetEvent.properties} />
                <PanelCard bg="white">
                  <PanelCardTitle>Organiser’s Description 👋</PanelCardTitle>
                  <Content>
                    {properties.targetEvent.properties!.description}
                  </Content>
                </PanelCard>
              </React.Fragment>
            )}

          {properties.whatsappLink && (
            <PanelCard sep sx={{ bg: 'white' }}>
              <PanelCardTitle>Get in touch</PanelCardTitle>

              <WhatsAppJoinButton
                href={properties.whatsappLink}
                name={properties.name}
              />
            </PanelCard>
          )}
        </React.Fragment>
      )}
    </Panel>
  )
}

const BasicDetailsCard: ViewElement<Pick<
  EventDetail_properties,
  'address' | 'startTime' | 'endTime'
>> = ({ address, startTime, endTime }) => (
  <PanelCard sep bg="white">
    <div
      sx={{
        variant: 'columns',
        alignItems: 'center',
        flexWrap: 'wrap',
        '> *': {
          mt: 2
        }
      }}
    >
      <div sx={{ flex: 1, width: '50%' }}>
        <h4 sx={{ variant: 'subheading', mb: 1 }}>Times ⏱</h4>
        <div sx={{ variant: 'body', mb: 3, fontWeight: 900, fontSize: 5 }}>
          <DateTimeValue
            relative
            value={startTime}
            format={DateTimeFormat.DATE_S}
          />
          &nbsp;
          <DateTimeValue value={startTime} format={DateTimeFormat.TIME} />
          {!!endTime && (
            <React.Fragment>
              &nbsp;
              <span>to</span>
              &nbsp;
              <DateTimeValue value={endTime} format={DateTimeFormat.TIME} />
            </React.Fragment>
          )}
        </div>

        {address && (
          <React.Fragment>
            <h4 sx={{ variant: 'subheading', mb: 1 }}>Go to 📍</h4>
            <Content
              sx={{
                variant: 'body',
                minWidth: 300,
                fontWeight: 900,
                fontSize: 5
              }}
            >
              {address}
            </Content>
          </React.Fragment>
        )}
      </div>
      {address && (
        <span>
          <LinkButtonExternal
            variant="outlined"
            color="primary"
            href={`https://www.google.com/maps/dir//${encodeURIComponent(
              address
            )}/`}
          >
            Get directions &rarr;
          </LinkButtonExternal>
        </span>
      )}
    </div>
  </PanelCard>
)

EventDetailView.fragment = gql`
  ${EventCategoryFragment}
  ${ElectionMarginChartView.fragment}

  fragment EventDetail on EventType {
    id
    properties {
      startTime
      endTime
      address
      postcode
      name
      description
      ...EventCategoryFragment
      isFull
      amAttending
      published
      whatsappLink
      labourPriority
      targetEvent {
        id
        properties {
          description
          startTime
          endTime
        }
      }
      manager {
        id
      }
      constituency {
        id
        name
        lastElection {
          ...ElectionMarginChart
        }
      }
    }
    geometry {
      type
      coordinates
    }
  }
`

export default EventDetailView
