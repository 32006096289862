import React, {
  FC,
  useMemo,
  useCallback,
  MouseEvent,
  CSSProperties,
  useEffect,
  useRef
} from 'react'
import { LinkProps } from 'react-router-dom'
import { LocationDescriptor, Location, LocationDescriptorObject } from 'history'
import { useHistory, useLocation } from 'react-router'
import { memoize } from 'lodash'
import { LazyRouteDef, usePreloadRoutes } from 'routes'
import { ensureArray } from 'helpers/array'

type PrefetchLinkProps = LinkProps & { route: LazyRouteDef }

/**
 * Link where navigation should be visually represented as pushing onto the nav stack
 * It's important to ensure a route's javascript is loaded before navigating as otherwise
 * we get a nasty flicker.
 *
 * TODO: load the data as well if it doesn't take too long
 */
export const PrefetchLink: FC<PrefetchLinkProps> = ({
  to,
  onClick,
  onMouseOver,
  route,
  ...props
}) => {
  usePreloadRoutes(route)
  const history = useHistory()
  const prefetch = useMemo(
    () =>
      memoize(async () => {
        if (route) {
          await route.preloadModule()
        }
      }),
    [route]
  )
  const navigate = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>) => {
      if (onClick) onClick(event)

      if (event.defaultPrevented) {
        return
      }

      event.preventDefault()
      await prefetch()

      if (typeof to === 'object') {
        history.push(to)
      } else {
        history.push(to)
      }
    },
    [to]
  )

  if (typeof to === 'string' && to.startsWith('http')) {
    return <a {...props} target="_blank" href={to} />
  }

  return (
    <a
      {...props}
      href={typeof to === 'string' ? to : history.createHref(to)}
      onClick={navigate}
    />
  )
}

/** Link where navigation should be visually represented as pushing onto the nav stack */
export const PushLink: FC<PrefetchLinkProps> = ({ to, ...props }) => {
  const { stackSize = 0 } = useLocation().state || {}

  return (
    <PrefetchLink
      to={mergeState(to, {
        stackAction: 'push',
        stackSize: stackSize + 1
      })}
      {...props}
    />
  )
}

/** Like PushLink but for imperative navigation */
export const usePushRoute = (
  route?: LazyRouteDef | LazyRouteDef[],
  { replace = false }: { replace?: boolean } = {}
) => {
  usePreloadRoutes(...ensureArray(route))

  const history = useHistory()
  const { stackSize = 0 } = history.location.state || {}

  return useCallback(
    async (location: LocationDescriptor<{}>) => {
      if (!route) {
        return
      }

      for await (const r of ensureArray(route)) {
        await r.preloadModule()
      }

      if (replace) {
        history.replace(
          mergeState(location, {
            stackAction: 'push'
          })
        )
      } else {
        history.push(
          mergeState(location, {
            stackAction: 'push',
            stackSize: stackSize + 1
          })
        )
      }
    },
    [route, history]
  )
}

/** Merge a state object into a location descriptor, merging with its existing state */
const mergeState = (
  desc: LocationDescriptor<{}>,
  state: any
): LocationDescriptorObject<{}> => {
  if (typeof desc === 'string') {
    return { pathname: desc, state }
  }

  return { ...desc, state: { ...state, ...desc.state } }
}

/**
 *  When the current page is the result of a back button press, return the location
 *  'popped' by going back, otherwise return undefined
 */
const usePoppedLocation = () => {
  const history = useHistory()
  const popedLocationRef = useRef<Location<any>>()
  useEffect(() => {
    // we don't actually want to block navigation, but this is guaranteed to run before
    // navigation
    return history.block((_, action) => {
      popedLocationRef.current = action === 'POP' ? history.location : undefined
    })
  })

  return popedLocationRef.current
}

export const useTransitionActions = (currentLocation: Location<any>) => {
  const poppedLocation = usePoppedLocation()

  const action = () => {
    const { stackSize = 0 } = currentLocation.state || {}
    const { stackAction: poppedAction, stackSize: prevStackSize = 0 } =
      (poppedLocation && poppedLocation.state) || {}

    // If we're going back, use the inverse of the transition used to get here
    if (poppedAction && prevStackSize > stackSize) {
      if (poppedAction === 'push') {
        return 'pop'
      }
      if (poppedAction === 'pop') {
        return 'push'
      }
    }

    // otherwise, use the requested enter transition
    return currentLocation.state && currentLocation.state.stackAction
  }

  /** get the appopriate css style for the current transition  */
  const transitionStyle = (types: Record<string, CSSProperties>) => {
    return types[action()] || {}
  }

  return { transitionStyle, action }
}
