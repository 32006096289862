import * as React from 'react'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'

import { isToday, isYesterday, parse, parseISO } from 'date-fns'
import { DateTimeFormat } from 'helpers/date'
import { Grid, TextField } from '@material-ui/core'
import { MaskedTextField } from './MaskedTextField'

/**
 * Read this before changing anything here!
 * https://github.com/marnusw/date-fns-tz#time-zone-helpers
 */

// Not exporting this because all date-time rendering goes through components in here
export { DateTimeFormat }

export const DateTimeValue: ViewElement<{
  value: string
  format?: string
  timezone?: string
  lower?: boolean
  relative?: boolean
}> = ({
  value,
  format: pattern = DateTimeFormat.ISO_DATE,
  timezone = 'Europe/London',
  lower,
  relative,
  ...props
}) => {
  const parsedDate = parseISO(value)
  const localDate = utcToZonedTime(parsedDate, timezone)

  if (relative) {
    if (isToday(localDate)) {
      return <span {...props}>Today</span>
    }

    if (isYesterday(localDate)) {
      return <span {...props}>Yesterday</span>
    }
  }

  const formatted = format(localDate, pattern)

  return <span {...props}>{lower ? formatted.toLowerCase() : formatted}</span>
}

export const DateCalendar: ViewElement<
  { timezone?: string; value: string; onChange: (change: string) => void },
  Omit<GetComponentProps<typeof DateTimePicker>, 'date'>
> = ({ value, onChange, timezone = 'Europe/London', ...props }) => {
  const parsedDate = value && parseISO(value)
  const localDate = parsedDate && utcToZonedTime(parsedDate, timezone)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        value={localDate || null}
        onChange={localChangedDate => {
          const utcDate =
            localChangedDate &&
            zonedTimeToUtc(new Date(localChangedDate), timezone)

          if (utcDate) {
            onChange(utcDate.toISOString())
          }
        }}
        inputVariant="outlined"
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
