/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { noop } from 'lodash'
import { register, authorise } from 'auth/authHelpers'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Typography } from '@material-ui/core'
import { FormWrapper, ProgressButton } from 'components/Elements'
import {
  Input,
  Checkbox,
  FormSection,
  FormGrid,
  FormError,
  FORM_GENERAL_ERROR
} from '../components/Form'
import { validatePostcode } from '../data/validators'

interface FormFields {
  email: string
  phone: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  postcode: string
  isContactable: boolean
  isPublic: boolean
  tags: string[]
}

export type RegistrationParams = Partial<FormFields>

export const RegisterForm: React.FC<{
  onAuthentication?: () => void
  onRequestSignIn?: () => void
  initialValues?: Partial<FormFields>
}> = ({ onAuthentication = noop, onRequestSignIn, initialValues = {} }) => {
  return (
    <RegisterFormContent
      onSubmit={async variables => {
        await register(variables)
        await authorise(variables)
        onAuthentication()
        return true
      }}
      initialValues={initialValues}
      onRequestSignIn={onRequestSignIn}
    />
  )
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .email()
    .required(),
  phone: yup.string(),
  postcode: yup
    .string()
    .required()
    .label('Postcode')
    .test(
      'Postcode',
      "This doesn't seem like a valid postcode",
      validatePostcode
    ),
  password: yup
    .string()
    .label('Password')
    .required()
    .min(8, 'Seems a bit short...')
    .max(4095, 'You know your Django'),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  firstName: yup
    .string()
    .required()
    .label('First name')
    .min(2, 'Seems a bit short...'),
  lastName: yup
    .string()
    .required()
    .label('First name')
    .min(2, 'Seems a bit short...'),
  isContactable: yup
    .boolean()
    .label('Contact details')
    .required()
    .oneOf(
      [true],
      'Your consent for organisers to contact you is important for effective campaigning'
    ),
  isPublic: yup
    .boolean()
    .label('Publicly viewable')
    .required()
} as {
  [prop in keyof FormFields]: any
})

export const RegisterFormContent: React.FC<{
  initialValues?: Partial<FormFields>
  onSubmit: (vars: FormFields) => Promise<boolean>
  onRequestSignIn?: () => void
}> = ({ initialValues, onSubmit, onRequestSignIn }) => {
  return (
    <React.Fragment>
      {onRequestSignIn && (
        <FormSection>
          <Typography variant="body1">
            <p>
              Register with My Campaign Map to get personalised event
              recommendations, reminders about upcoming events and the ability
              to add your own events to our map.
            </p>
            <p>
              Already registered?{' '}
              <span
                sx={{ fontWeight: 700, color: 'primary', cursor: 'pointer' }}
                onMouseDown={() => onRequestSignIn()}
              >
                sign in
              </span>{' '}
              instead
            </p>
          </Typography>
        </FormSection>
      )}
      <Formik<FormFields>
        initialValues={{
          email: '',
          phone: '',
          postcode: '',
          password: '',
          confirmPassword: '',
          firstName: '',
          lastName: '',
          isContactable: true,
          isPublic: false,
          tags: [],
          ...initialValues
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            setSubmitting(true)
            await onSubmit(values)
            setSubmitting(false)
          } catch (e) {
            console.error(e)

            setSubmitting(false)
            setFieldError(
              FORM_GENERAL_ERROR,
              'We couldn’t create your account. If you’ve already registered, try signing in'
            )
          }
        }}
      >
        {({ touched, handleSubmit, isSubmitting, isValid }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <FormSection label="Tell us about yourself">
              <Input required fullWidth name="email" autoComplete="email" />

              <FormGrid width="50%">
                <Input required fullWidth name="password" type="password" />

                <Input
                  required
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                />

                <Input
                  required
                  fullWidth
                  name="firstName"
                  label="First name"
                  autoComplete="name given-name nickname cc-name first-name"
                />
                <Input
                  required
                  fullWidth
                  name="lastName"
                  label="Last name"
                  autoComplete="name family-name cc-given-name cc-family-name"
                />
                <Input
                  required
                  name="postcode"
                  autoComplete="postcode"
                  helperText="So we know what events to tell you about"
                />
                <Input
                  fullWidth
                  label="Mobile number"
                  name="phone"
                  autoComplete="phone"
                  helperText="So we can contact you about events you’re organising"
                />
              </FormGrid>
            </FormSection>
            {/*
            <FormSection grid label="Tell us how you can help">
              <FormGrid width="50%">
                {tags.map(({ value, label }) => (
                  <ArrayCheckbox
                    key={value}
                    name="tags"
                    value={value}
                    label={label}
                  />
                ))}
              </FormGrid>

              {association_tags.map(({ value, label, description }) => (
                <ArrayCheckbox
                  key={value}
                  name="tags"
                  value={value}
                  label={
                    <React.Fragment>
                      <b>{label}</b>
                      <p sx={{ my: 1 }}>{description}</p>
                    </React.Fragment>
                  }
                />
              ))}
            </FormSection> */}

            <FormSection>
              <React.Fragment>
                By providing your email and telephone number you agree to Momentum
                using the information you provide to keep you updated via email,
                telephone and text about Momentum’s campaigns and opportunities
                to get involved, both locally and on a national level. For more
                information please see our{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://peoplesmomentum.com/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </React.Fragment>

              <FormError />

              <ProgressButton
                disabled={!touched || !isValid}
                size="large"
                type="submit"
                loading={isSubmitting}
              >
                Register
              </ProgressButton>
            </FormSection>
          </FormWrapper>
        )}
      </Formik>
    </React.Fragment>
  )
}
