/** @jsxFrag jsx */

import { jsx } from 'theme-ui'
import * as React from 'react'
import gql from 'graphql-tag'
import { useAnalytics } from 'data/analytics'

import { RecommendationView } from 'views/__graphql__/RecommendationView'
import { Panel, PanelHeader, PanelCard, PanelCardTitle } from './Elements'
import { EventRecommendationsList } from '../event/EventRecommendationList'
import { CommunicationGroupRecommendationsList } from './CommunicationGroupRecommendationsList'
import { AddButton } from './AddButton'
import { CatOrSubcat } from 'views/queries'

export enum ActivistMode {
  Convenience = 'Convenience',
  Effectiveness = 'Effectiveness'
}

export enum RecommendationType {
  Groups,
  Events
}

// TODO: don't make this either or, be able to show both
export const recommendationType: RecommendationType = RecommendationType.Groups

const PostcodeRecommendations: ComponentWithFragment<
  RecommendationView & {
    postcode: string
    mode: ActivistMode
    setMode: (x: ActivistMode) => void
    loading?: boolean
    subcategories: CatOrSubcat[]
  }
> = ({ postcode, groups, events, loading, subcategories }) => {
  const analytics = useAnalytics()

  return (
    <React.Fragment>
      <Panel
        sx={{
          bg: '#F8F8F8',
          height: '100%',
          gridTemplateRows: 'repeat(6, min-content) 1fr'
        }}
      >
        <PanelHeader
          sx={{
            height: 'min-content'
          }}
          breadCrumbs={[
            { to: '/', text: 'Home' },
            {
              to: `/recommendations/${postcode}`,
              text: 'Search Results'
            }
          ]}
        />

        <PanelCard sep>
          <PanelCardTitle sx={{ fontSize: 5, color: 'black', mb: 3 }}>
            💬 Say hey to nearby volunteers and campaign together!
          </PanelCardTitle>

          <p sx={{ my: 1 }}>
            Find your local action group, and find events in your area.
          </p>
        </PanelCard>

        {recommendationType === RecommendationType.Groups ? (
          <CommunicationGroupRecommendationsList groups={groups} />
        ) : (
          <>
            <AddButton sep />
            <EventRecommendationsList
              recommendations={events}
              subcategories={subcategories}
              loading={loading}
              currentPostcode={postcode}
            />
          </>
        )}
      </Panel>
    </React.Fragment>
  )
}

export const DATA_CAPTURE = gql`
  mutation DataCaptureMutation($email: String!, $postcode: String!) {
    requestUpdates(email: $email, postcode: $postcode, isContactable: true) {
      success
    }
  }
`

export default PostcodeRecommendations
