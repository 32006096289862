/* eslint-disable no-underscore-dangle */
/** @jsx jsx */

import { jsx } from 'theme-ui'

import * as React from 'react'
import { Field, FieldProps } from 'formik'
import { capitalise } from 'helpers/string'
import {
  TextField,
  FormHelperText,
  Checkbox as MuiCheckbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { RadioGroupProps } from '@material-ui/core/RadioGroup'
import Grid, { GridProps } from '@material-ui/core/Grid'
import { PanelCard, PanelCardTitle } from './Elements'

type FormComponent<ComponentProps, OtherProps = {}> = ViewElement<
  OtherProps & { name: string },
  ComponentProps
>

export const _Input: FormComponent<TextFieldProps> = ({
  name,
  label,
  error,
  helperText,
  ...props
}) => {
  return (
    <Field
      name={name}
      render={(formik: FieldProps) => (
        <TextField
          {...formik.field}
          variant="outlined"
          error={!!formik.form.touched[name] && !!formik.form.errors[name]}
          helperText={
            (!!formik.form.touched[name] && formik.form.errors[name]) ||
            helperText
          }
          label={(label !== false && (label || capitalise(name))) || undefined}
          onChange={event => {
            formik.form.setFieldValue(name, event.currentTarget.value)
          }}
          {...(props as any)}
        />
      )}
    />
  )
}
export const Input = React.memo(_Input)

const _Checkbox: FormComponent<CheckboxProps, { label: React.ReactNode }> = ({
  name,
  onChange,
  disabled,
  label,
  className,
  ...props
}) => (
  <Field
    name={name}
    render={(formik: FieldProps) => (
      <FormControlLabel
        disabled={disabled}
        className={className}
        label={<Typography variant="body2">{label}</Typography>}
        control={
          <MuiCheckbox
            {...formik.field}
            onChange={(_, checked) => formik.form.setFieldValue(name, checked)}
            {...props}
          />
        }
      />
    )}
  />
)
export const Checkbox = React.memo(_Checkbox)

const _ArrayCheckbox: FormComponent<
  CheckboxProps,
  { label: React.ReactNode }
> = ({ name, value, onChange, disabled, label, className, ...props }) => (
  <Field
    name={name}
    render={(formik: FieldProps) => {
      const checked = formik.field.value.includes(value)

      return (
        <FormControlLabel
          disabled={disabled}
          className={className}
          label={<Typography variant="body2">{label}</Typography>}
          control={
            <MuiCheckbox
              {...formik.field}
              checked={checked}
              onChange={(_, checked) =>
                formik.form.setFieldValue(
                  name,
                  checked
                    ? [...formik.field.value, value]
                    : formik.field.value.filter(x => x !== value)
                )
              }
              {...props}
            />
          }
        />
      )
    }}
  />
)
export const ArrayCheckbox = React.memo(_ArrayCheckbox)

const _LargeRadioOption: FormComponent<
  PaperProps,
  {
    label: React.ReactNode
    description?: React.ReactNode
    disabled?: boolean
    value: string
    selected?: boolean
  }
> = ({
  name,
  selected: selectedOverride,
  label,
  description,
  disabled,
  value,
  onClick
}) => (
  <Field
    name={name}
    render={({ form: { setFieldValue }, field }: FieldProps) => {
      const selected =
        typeof selectedOverride === 'boolean'
          ? selectedOverride
          : field.value === value

      return (
        <div
          sx={{
            cursor: disabled ? 'default' : 'pointer',
            flexShrink: 0,
            userSelect: 'none',
            py: 1,
            px: 3,
            borderBottom: 'light',
            opacity: disabled ? 0.5 : 1,
            '&:last-child': { borderBottom: 'none' }
          }}
          onClick={
            disabled
              ? undefined
              : (event: React.MouseEvent<{}>) => {
                  if (onClick) {
                    onClick(event)
                  }
                  setFieldValue(name, value)
                }
          }
        >
          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>
              <h3
                sx={{
                  mb: 1,
                  color: selected && 'primary'
                }}
              >
                {label}
              </h3>
              <p sx={{ color: 'gray.8', mt: 0, fontSize: 2 }}>{description}</p>
            </div>
            <Radio disabled={disabled} size="medium" checked={selected} />
          </div>
        </div>
      )
    }}
  />
)
export const LargeRadioOption = React.memo(_LargeRadioOption)

export const RadioOptionGroup: ViewElement<
  { label: string },
  RadioGroupProps
> = ({ children, label, ...props }) => (
  <RadioGroup aria-label={label} {...props}>
    <Typography sx={{ px: 1, py: 2 }} variant="subtitle2">
      {label}
    </Typography>
    <Paper sx={{ borderRadius: 2 }} elevation={1}>
      {children}
    </Paper>
  </RadioGroup>
)

export const FormSection: ViewElement<
  { label?: string; grid?: boolean },
  GridProps
> = ({ label, ...props }) => (
  <PanelCard sep="lightThick">
    {label && (
      <PanelCardTitle sx={{ fontSize: 5, mb: 3 }}>{label}</PanelCardTitle>
    )}

    <FormGrid {...props} />
  </PanelCard>
)

export const FormGrid: ViewElement<{ width?: string | number }, GridProps> = ({
  children,
  width = '100%',
  ...props
}) => (
  <Grid container direction="row" wrap="wrap" spacing={3} {...props}>
    {React.Children.map(
      children,
      (child, i) =>
        child && (
          <Grid sx={{ width }} key={i} item>
            {child}
          </Grid>
        )
    )}
  </Grid>
)

export const FORM_GENERAL_ERROR = 'FORM_GENERAL_ERROR'

export const FormError: ViewElement<{ name?: string }> = ({
  name = FORM_GENERAL_ERROR,
  children,
  ...props
}) => (
  <Field name={name}>
    {({ form }: FieldProps) =>
      form.errors[name] ? (
        <FormHelperText error {...props}>
          {children || form.errors[name]}
        </FormHelperText>
      ) : null
    }
  </Field>
)
