/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { noop } from 'lodash'
import { resetPassword } from 'auth/authHelpers'
import { Formik, FormikActions } from 'formik'
import * as yup from 'yup'
import { ProgressButton, FormWrapper } from 'components/Elements'
import { preventDefault } from 'helpers/dom'
import { Button, Typography } from '@material-ui/core'
import {
  Input,
  FormSection,
  FormGrid,
  FORM_GENERAL_ERROR,
  FormError
} from '../components/Form'

interface FormFields {
  email: string
}

export const PasswordResetForm: React.FC<{
  onCompleted?: () => void
}> = ({ onCompleted = noop }) => {
  const [completed, setCompleted] = React.useState(false)
  return (
    <PasswordResetFormContents
      completed={completed}
      onSubmit={async ({ email }, { setSubmitting, setFieldError }) => {
        try {
          await resetPassword(email)
          setSubmitting(false)
          setCompleted(true)

          return true
        } catch (e) {
          console.error(e)
          setSubmitting(false)
          setFieldError(
            FORM_GENERAL_ERROR,
            'Sorry, something went wrong. Please try again later.'
          )
        }
      }}
      onCompleted={onCompleted}
    />
  )
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .email()
    .required()
} as {
  [prop in keyof FormFields]: any
})

export const PasswordResetFormContents: React.FC<{
  onSubmit: (
    vars: FormFields,
    actions: FormikActions<FormFields>
  ) => Promise<boolean>
  onCompleted: () => void
  completed: boolean
}> = ({ onSubmit, completed, onCompleted }) => (
  <Formik<FormFields>
    initialValues={{ email: '' }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ handleReset, handleSubmit, isSubmitting, values }) => {
      if (completed) {
        return (
          <FormSection>
            <p>
              An email to reset your password has been sent to {values.email}.
            </p>

            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={onCompleted}
            >
              Ok
            </Button>
          </FormSection>
        )
      }

      return (
        <FormWrapper onReset={handleReset} onSubmit={handleSubmit}>
          <FormSection>
            <Typography variant="body1">
              Enter your email address to get a password reset link.
            </Typography>
          </FormSection>

          <FormSection sx={{ mt: 4 }}>
            <Input required fullWidth name="email" autoComplete="email" />

            <FormError>
              We couldn’t reset your password. Please try again later.
            </FormError>

            <FormGrid width="50%">
              <ProgressButton size="large" type="submit" loading={isSubmitting}>
                Reset Password
              </ProgressButton>
            </FormGrid>
          </FormSection>
        </FormWrapper>
      )
    }}
  </Formik>
)
