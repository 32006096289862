import bboxPolygon from '@turf/bbox-polygon'
import center from '@turf/center'
import { point, featureCollection, BBox, Feature } from '@turf/helpers'
import rhumbDistance from '@turf/rhumb-distance'
import circle from '@turf/circle'
import difference from '@turf/difference'
import bbox from '@turf/bbox'
import bboxClip from '@turf/bbox-clip'
import gql from 'graphql-tag'
import { tuple } from './array'

const WORLD_BOUNDS = [-180, -85.1054596961173, 180, 85.1054596961173]

export type PointsetType = [[number, number], [number, number]]

export const bboxToPointset = (b: BBox): PointsetType => {
  return [[b[0], b[1]], [b[2], b[3]]]
}

const cardinalAccessors = (b: BBox) => ({
  // Maintain the numeric indexes
  ...b,
  // Add syntactic shortcuts
  w: b[0],
  s: b[1],
  e: b[2],
  n: b[3]
})

// number[][] => number[]
export const pointsetToBbox = (b: PointsetType): BBox => {
  return [b[0][0], b[0][1], b[1][0], b[1][1]]
}

export const pointsetToCircle = (bounds, properties) => {
  const box = bboxPolygon(pointsetToBbox(bounds))
  const centerPoint = center(featureCollection([box]))
  const extentPoint = i => point(bounds[i])
  const radius = rhumbDistance(extentPoint(0), extentPoint(1)) / 2 // radius between the SW / NE
  // @ts-ignore: turf interfaces are out-of-date
  const searchGeom = circle(centerPoint, radius || 10)
  return searchGeom
}

export const maskedOutsideFeature = <F extends Feature<any>>(feature: F) => {
  const { properties } = feature
  const maskBounds = bboxPolygon(WORLD_BOUNDS as BBox)
  const diff = difference(maskBounds, feature) as Feature<F['properties']>
  if (!diff) return null
  return { ...diff, properties }
}

export const maskedOutside = (feature: any) => {
  const diff = maskedOutsideFeature(feature)
  if (!diff) {
    return featureCollection([])
  }
  return featureCollection([diff])
}

const defaultBBox: BBox = [0, 0, 0, 0]

export const featuresToBounds = (
  points: GeoJSONFeature<any>[],
  opts: { cardinal?: boolean } = {}
): BBox => {
  // 1. Try simple bounding box
  try {
    if (!points || points.length === 0) return defaultBBox
    const geom = featureCollection(points)
    if (!geom) return defaultBBox
    const simpleBBox = bbox(geom)
    if (!simpleBBox) return defaultBBox
    const bboxWidth = simpleBBox[2] - simpleBBox[0]

    if (bboxWidth < 180 || !opts.cardinal) {
      return simpleBBox
    }
  } catch (e) {
    return defaultBBox
  }

  /*
    2. Find the cardinal extents of the geometry, irregardless of antimeridian
  
    We normalise coordinates that cross the antimeridian to a double-latitude coordinate range
    i.e.
    -180  0   180/-180   0   -180/0
    becomes
    0     180     360     540     720
    
    Which allows us to simply and clearly compare minimum and maximum boundary extents.
    from which to construct a final, antimeridian-agnostic bbox.
  */

  // For each feature, create bboxes on either side of the meridian (east and west hemispheres)
  // and combine their minimum and maximum coordinates into a new bbox, encoded in a double-latitude coordinate range
  const bboxCollection = points.map(f => {
    const east = cardinalAccessors(bbox(bboxClip(f, EAST_HEMISPHERE_BBOX)))
    const west = cardinalAccessors(bbox(bboxClip(f, WEST_HEMISPHERE_BBOX)))
    return cardinalAccessors([
      // w,
      east.w + TEMP_RANGE_OFFSET,
      // s,
      Math.min(east.s, west.s),
      // e
      west.e + TEMP_RANGE_OFFSET + PERM_RANGE_EXTENSION,
      // n
      Math.max(east.n, west.n)
    ])
  })

  // Find cardinal extents and remove temporary normalisation offset
  const extent: BBox = [
    // w
    Math.min(...bboxCollection.map(bbox => bbox.e)) - TEMP_RANGE_OFFSET, // s,
    Math.min(...bboxCollection.map(bbox => bbox.s)), // e,
    Math.max(...bboxCollection.map(bbox => bbox.w)) - TEMP_RANGE_OFFSET, // n
    Math.max(...bboxCollection.map(bbox => bbox.n))
  ]

  return extent
}

export const bboxFromCoords = (coords: {
  latitude: any
  longitude: any
}): [number, number] => [
  parseFloat(coords.latitude),
  parseFloat(coords.longitude)
]

export const geometryFragment = gql`
  fragment GeoJSON on GeometryObjectType {
    type
    coordinates
  }
`

export const minBboxSize = (
  [minX, minY, maxX, maxY]: BBox,
  [w, h]: [number, number]
) => {
  if (maxY - minY < h) {
    minY -= h * 0.5
    maxY += h * 0.5
  }
  if (maxX - minX < w) {
    minX -= w * 0.5
    maxX += w * 0.5
  }

  return tuple(minX, minY, maxX, maxY)
}

export const CONSTITUENCY_LAYER_ID = 'constituencies'
export const CONSTITUENCY_SOURCE_LAYER_ID =
  'Westminster_Parliamentary_Con-6i1rlq'
export const CONSTITUENCY_NAME_PROPERTY = 'pcon16nm'
export const CONSTITUENCY_ID_PROPERTY = 'pcon16cd'
export const CONSTITUENCY_MAP_SOURCE = {
  sourceId: 'composite',
  sourceLayer: 'Westminster_Parliamentary_Con-6i1rlq'
}
