import * as React from 'react'
import { IMapHelpers, EnhancedMouseEventFunc, MapEvent } from 'map/Map'

interface GeoContextInterface {
  mapHelper?: IMapHelpers
}

export const MapHelperContext = React.createContext<GeoContextInterface>({
  mapHelper: undefined
})

export const useGeoContext = () => {
  return React.useContext(MapHelperContext)
}

export const useMapEventHandlers = (listeners: {
  onClick?: EnhancedMouseEventFunc
  onMouseDown?: EnhancedMouseEventFunc
  onHover?: EnhancedMouseEventFunc
}) => {
  const { mapHelper } = useGeoContext()

  // Handle events as an extension of the base map
  React.useEffect(() => {
    if (!mapHelper) return

    Object.entries(listeners).forEach(([on, cb]) => {
      mapHelper.addListener(on as MapEvent, cb)
    })

    return () => {
      Object.entries(listeners).forEach(([on, cb]) => {
        mapHelper.removeListener(on as MapEvent, cb)
      })
    }
  }, [listeners, mapHelper])
}
