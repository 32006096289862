/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { hot } from 'react-hot-loader/root'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
// / Views
import BackgroundMap from 'map/BackgroundMap'
// /
import { analytics } from 'data/analytics'
import { PORTAL_ROOT, PLATFORM } from 'symbols'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorMessage from 'components/ErrorMessage'
import CookieConsentBanner from 'components/CookieBanner'
import { SafeAreaView } from 'components/SafeAreaView'
import * as routes from 'routes'
import { useLocation, useHistory } from 'react-router'
import { animated, useTransition } from 'react-spring'
import { LoadingArea } from 'components/Elements'
import { useTransitionActions } from 'components/Navigation'
import { TabBar } from 'components/TabBar'
import { isMobile } from 'react-device-detect'
import { WhatsAppJoinShim } from 'components/WhatsAppJoinButton'
import { decode } from 'querystring'
import { useFeatureFlag } from 'data/hooks'

const App = () => {
  const livestreamsVisible = useFeatureFlag('livestreams')
  const isLivestreams =
    livestreamsVisible && window.location.href.indexOf('/livestreams') >= 0
  const currentSite = isLivestreams ? 'livestreams' : 'groups'
  const showMap = !isLivestreams && !isMobile
  return (
    <div
      id={PORTAL_ROOT}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
      }}
    >
      {livestreamsVisible && <TabBar currentSite={currentSite} />}
      <div
        sx={{
          variant: 'columns',
          height: '100%',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <ErrorBoundary>
          <SafeAreaView
            sx={{
              variant: 'rows',
              position: 'relative',
              backgroundColor: 'white',
              flexShrink: 0,
              minWidth: ['100%', '320px'],
              width: '500px',
              maxWidth: ['100%', '50%'],
              height: '100%',
              zIndex: 1
            }}
          >
            <React.Suspense fallback={<LoadingArea />}>
              <animated.div
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <Switch>
                  {/* Landing page */}
                  <Route exact path="/" component={routes.Landing} />

                  {
                    /* Join whatsapp group */
                    <Route path="/join">
                      {({ history, location }) => {
                        const { uri, name } = decode(location.search.substr(1))
                        return (
                          <React.Fragment>
                            <WhatsAppJoinShim
                              name={String(name)}
                              href={String(uri)}
                              onClose={() => history.replace('/')}
                            />
                            <routes.Landing />
                          </React.Fragment>
                        )
                      }}
                    </Route>
                  }
                  {/* Recommendations page */}
                  <Route
                    exact
                    path={`/recommendations/:postcode`}
                    component={routes.Recommendations}
                  />
                  {/* AllEvents page */}
                  <Route
                    exact
                    path={`/allevents/:date/:postcode/:radius`}
                    component={routes.AllEvents}
                  />
                  <Route
                    exact
                    path={`/events/:category`}
                    component={routes.CategorisedEvents}
                  />
                  {livestreamsVisible && (
                    <Route
                      exact
                      path="/livestreams"
                      component={routes.LivestreamPage}
                    />
                  )}
                  {/* Create event page */}
                  <Route
                    exact
                    path="/create-event"
                    component={routes.CreateEvent}
                  />

                  {/* Event page */}
                  <Route exact path="/event/:id" component={routes.Event} />

                  {/* Constituency page */}
                  <Route
                    exact
                    path="/constituency/:id"
                    component={routes.Constituency}
                  />

                  {/* UserPreferences page */}
                  <Route
                    exact
                    path="/preferences"
                    component={routes.UserPreferences}
                  />

                  <Redirect to="/" />
                </Switch>
                {/* </ErrorBoundary> */}
              </animated.div>
            </React.Suspense>
          </SafeAreaView>
          {/* Maps are separately driven by the router,
      displaying useful context geographically */}
          {showMap && (
            <BackgroundMap
              sx={{
                flex: 1,
                height: '100%'
              }}
            />
          )}

          <CookieInitializer />
        </ErrorBoundary>
      </div>
    </div>
  )
}

const CookieInitializer = () => {
  React.useEffect(() => {
    if (PLATFORM === 'cordova') {
      analytics.initialiseCookies()
    }
  }, [])

  if (PLATFORM === 'web') {
    return (
      <CookieConsentBanner
        onConsent={consented => {
          if (consented) {
            analytics.initialiseCookies()
          }
        }}
      />
    )
  }

  return null
}

export default hot(App)
