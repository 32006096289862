import { compact, flatMap } from 'lodash'

export const compare = <T>(f: (d: T) => any, desc?: boolean) => (a: T, b: T) =>
  (f(desc ? a : b) as number) - (f(desc ? b : a) as number)

export const limit = <T>(limit: number) => (
  item: T,
  index: number,
  group: T[]
) => index < limit

export const ensureArray = <T>(input: T | T[]): T[] =>
  Array.isArray(input) ? input : [input]

export const toggle = <T>(input: T[], value: T) =>
  input.includes(value) ? input.filter(x => x !== value) : [...input, value]

export const tuple = <T extends unknown[]>(...xs: T) => xs

export const findFirstDefined = <T>(x: T[]) => x.find(Boolean)
