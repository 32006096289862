import * as React from 'react'

export type LazyRouteDef = ReturnType<typeof createLazy>
export type LazyRouteDefWithData = ReturnType<typeof createLazy>

/** Wrap a route def in react.lazy and add helper for preloading the route and one for preloading its data */
function createLazy<T extends React.ComponentType<any>>(
  moduleFn: () => Promise<{ default: T }>
) {
  let Eager: T | undefined
  const Lazy = React.lazy(moduleFn)

  const Component = (props: any) => {
    if (Eager) {
      return <Eager {...props} />
    }
    return <Lazy {...props} />
  }

  return Object.assign(Component, {
    preloadModule: async () => {
      const { default: component } = await moduleFn()
      Eager = component
    }
  })
}

/** Declare the routes a screen might navigate to so that they can be optimistically loaded */
export const usePreloadRoutes = (...routes: (LazyRouteDef | undefined)[]) => {
  React.useEffect(() => {
    for (const route of routes) {
      if (route) {
        route.preloadModule()
      }
    }
  }, [])
}

export const Recommendations = createLazy(() =>
  import(/* webpackChunkName: 'view-Recommendations' */ 'views/Recommendations')
)

export const CategorisedEvents = createLazy(() =>
  import(
    /* webpackChunkName: 'view-CategorisedEvents' */ 'views/CategorisedEvents'
  )
)

export const AllEvents = createLazy(() =>
  import(/* webpackChunkName: 'view-AllEvents' */ 'views/AllEvents')
)

export const Landing = createLazy(() =>
  import(/* webpackChunkName: 'view-Landing' */ 'views/Landing')
)

export const LivestreamPage = createLazy(() =>
  import(/* webpackChunkName: 'view-LivestreamPage' */ 'views/LivestreamPage')
)

export const Event = createLazy(() =>
  import(/* webpackChunkName: 'view-Event' */ 'views/Event')
)

export const UserPreferences = createLazy(() =>
  import(/* webpackChunkName: 'view-UserPreferences' */ 'views/UserPreferences')
)

export const CreateEvent = createLazy(() =>
  import(/* webpackChunkName: 'view-CreateEvent' */ 'views/CreateEvent')
)

export const Constituency = createLazy(() =>
  import(/* webpackChunkName: 'view-Constituency' */ 'views/Constituency')
)
