/** @jsx jsx */

import { jsx } from 'theme-ui'
import gql from 'graphql-tag'
import React from 'react'
import { ConstituencyView_constituencyTravelToEvents } from 'views/__graphql__/ConstituencyView'
import { Panel, LoadingArea, PanelHeader, PanelSectionTitle } from './Elements'
import { ConstituencyDashboard } from './__graphql__/ConstituencyDashboard'
import { EventRecommendationsList } from '../event/EventRecommendationList'
import { AddButton } from './AddButton'
import { CommunicationGroupRecommendationsList } from './CommunicationGroupRecommendationsList'
import { EventCategoryFragment } from '../event/EventFragments'
import Collapser from './Collapser'
import { expanderSx } from './ConstituencyPollingDayGuide'

const ConstituencyDashboardView: ComponentWithFragment<
  { constituency?: ConstituencyDashboard | null } & Partial<
    ConstituencyView_constituencyTravelToEvents
  >,
  { loading?: boolean; postcode?: string }
> = ({ constituency, loading, travelToEvents, postcode }) => {
  if (loading) {
    return (
      <Panel>
        <PanelHeader
          breadCrumbs={[
            { to: '/', text: 'Home' },
            ...(postcode
              ? [{ to: `/recommendations/${postcode}`, text: 'Search Results' }]
              : [])
          ]}
        />
        <LoadingArea>
          Searching for the movement in this constituency
        </LoadingArea>
      </Panel>
    )
  }

  if (!constituency) {
    return null
  }

  const { groups, upcomingEvents, name, id } = constituency

  return (
    <React.Fragment>
      <Panel>
        <PanelHeader
          breadCrumbs={[
            { to: '/', text: 'Home' },
            ...(postcode
              ? [{ to: `/recommendations/${postcode}`, text: 'Search Results' }]
              : []),
            { to: `/constituency/${id}`, text: name }
          ]}
        />

        <PanelSectionTitle bg="contrast.0">
          <h1 sx={{ variant: 'heading', mb: 0, fontSize: 5 }}>{name}</h1>
          <div sx={{ m: 0, color: 'grey', fontSize: 1 }}>
            Parliamentary constituency
          </div>
        </PanelSectionTitle>

        <AddButton
          context={{
            constituency: constituency.id,
            constituencyName: constituency.name,
            postcode: postcode
          }}
        />

        {groups.length > 0 && (
          <Collapser items={groups} collapsedCount={2}>
            {(items, toggle, text, canExpand) => (
              <React.Fragment>
                <CommunicationGroupRecommendationsList groups={items} />
                {canExpand && (
                  <div onClick={toggle} sx={expanderSx}>
                    {text}
                  </div>
                )}
              </React.Fragment>
            )}
          </Collapser>
        )}

        {upcomingEvents.length > 0 && (
          <React.Fragment>
            <PanelSectionTitle
              sx={{ fontSize: 2, py: 2 }}
              bg="contrast.0"
              sep="lightThick"
            >
              Upcoming in {name}
            </PanelSectionTitle>

            <EventRecommendationsList recommendations={upcomingEvents} />
          </React.Fragment>
        )}
      </Panel>
    </React.Fragment>
  )
}

ConstituencyDashboardView.fragment = gql`
  ${EventCategoryFragment}
  ${CommunicationGroupRecommendationsList.fragment}

  fragment ConstituencyDashboard on ConstituencyType {
    id
    name
    upcomingEvents {
      id
      properties {
        name
        ...EventCategoryFragment
        startTime
        endTime
        amAttending
      }
      geometry {
        type
        coordinates
      }
    }
    groups: communicationgroupSet {
      ...CommunicationGroupRecommendation
    }
  }
`

export default ConstituencyDashboardView
