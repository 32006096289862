/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'

export default function Collapser<T>({
  items,
  initialCollapsed = true,
  isCollapsible = true,
  collapsedCount = 3,
  children
}: {
  items: T[]
  initialCollapsed?: boolean
  isCollapsible?: boolean
  collapsedCount?: number
  children: (
    items: T[],
    toggle: () => void,
    collapsibleText: any,
    hasEnoughThingsToCollapse: boolean
  ) => void
}) {
  const [isCollapsed, setCollapsed] = useState<boolean>(initialCollapsed)

  const outputList = items.filter(
    isCollapsible
      ? (_, i) => (isCollapsed ? i < collapsedCount : true)
      : Boolean
  )

  return children(
    outputList,
    () => setCollapsed(b => !b),
    isCollapsed ? 'Show more ⏬' : 'Show fewer ⏫',
    collapsedCount < items.length
  )
}
