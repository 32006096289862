import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { compare } from 'helpers/array'
import gql from 'graphql-tag'
import { useAuth } from 'data/hooks'
import EventCard from '../event/EventCard'
import { isAttendable } from '../helpers/date'

export const upcomingCommitmentsFragment = gql`
  ${EventCard.fragment}

  fragment UpcomingCommitments on UserType {
    commitments {
      ...EventCard
      properties {
        amAttending
      }
    }
    commitmentCount
    upcomingCommitmentCount
  }
`

export const QUERY_EVENT_LIST = gql`
  ${upcomingCommitmentsFragment}

  query YourEvents {
    currentUser {
      id
      ...UpcomingCommitments
    }
  }
`

export const useYourUpcomingEvents = () => {
  const { state } = useAuth()
  const query = useQuery<{ currentUser: { id: string; commitments: Event[] } }>(
    QUERY_EVENT_LIST
  )
  React.useEffect(() => void query.refetch(), [state])
  return query
}

const YourUpcomingEvents: React.FC = () => {
  const { data, loading, error } = useYourUpcomingEvents()

  return (
    <div>
      {data &&
      data.currentUser &&
      Array.isArray(data.currentUser.commitments) ? (
        data.currentUser.commitments
          .filter(d => isAttendable(d.properties.startTime))
          .sort(compare(d => new Date(d.properties.startTime), true))
          .map(event => {
            return <EventCard key={event.id} {...event} />
          })
      ) : loading ? (
        <div>Loading</div>
      ) : error ? (
        <div>Error</div>
      ) : (
        <div>Nothing to see here</div>
      )}
    </div>
  )
}

export default YourUpcomingEvents
