/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import MapGL, {
  FlyToInterpolator,
  InteractiveMapProps,
  ViewState,
  PointerEvent
} from 'react-map-gl'
import { isEqual } from 'lodash'
import { BBox } from '@turf/helpers'
import WebMercatorViewport, { Padding } from 'viewport-mercator-project'
import { MapContext, ZoomControl } from 'react-mapbox-gl'
import { bboxToPointset, minBboxSize } from 'helpers/geo'
// import { BaseMap, featureBounds } from 'components/DeclarativeMap'
import { usePrevious } from 'helpers/hooks'
import { MapHelperContext } from 'map/MapHelperContext'
import { featuresToBounds } from '../helpers/geo'

type Viewport = ViewState & Partial<InteractiveMapProps>
type FitFeaturesOverrides = (
  prevViewport: Viewport,
  nextViewport: Viewport
) => Partial<Viewport>
type MouseEventFunc = (event: PointerEvent) => void
export type EnhancedMouseEventFunc = (
  event: PointerEvent,
  lngLat: number[],
  features: mapboxgl.MapboxGeoJSONFeature[]
) => void
export type MapEvent = 'onClick' | 'onMouseDown' | 'onHover'

export interface IMapHelpers {
  /**
   * Access the MapBoxGl object.
   */
  getMapObject: () => mapboxgl.Map | false
  /**
   * Helper method: Flies the camera to the new bbox.
   * Use in conjunction with `featuresToBounds`.
   */
  updateViewportBounds: (
    bounds?:
      | [number, number, number, number]
      | [number, number, number, number, number, number]
      | null,
    options?: FitBoundsOptions,
    viewportRestrictions?: FitFeaturesOverrides
  ) => void
  /**
   * Provide the relative scale of an object.
   * Use to render markers on 3D maps with depth.
   */
  scaleFromDepth: (coordinates: [number, number]) => number
  getMapEventFeature: (e: PointerEvent) => any | null
  addListener: (on: MapEvent, cb: EnhancedMouseEventFunc) => void
  removeListener: (on: MapEvent, cb: EnhancedMouseEventFunc) => void
}

interface IProps extends Partial<Omit<InteractiveMapProps, MapEvent>> {
  mapboxStyleUrl?: string
  mapboxToken?: string
  width: number
  height: number
  bounds?: BBox
  initialViewport?: Partial<ViewState>
  minSize: [number, number]
  children: React.ReactNode
  // collectMapHelpers: (helpers: IMapHelpers) => void // use ref instead
  fitFeatures?: GeoJSONFeature[]
  /**
   * Rules to abide by when fitting the camera to bounds or features.
   *
   * @type {FitBoundsOptions}
   * @memberof IProps
   */
  fitBoundsOptions?: FitBoundsOptions
  /**
   * Override viewport property updates before committing them to state.
   *
   * @type {FitFeaturesOverrides}
   * @memberof IProps
   */
  fitBoundsOverrides?: FitFeaturesOverrides
  getMapObject?: (map: mapboxgl.Map | undefined) => void
  onMapLoad?: (map: mapboxgl.Map, mapHelpers: IMapHelpers) => void
  /**
   * Recycle the map object to save memory.
   * NB: Beware of style persistence across map objects!
   * @type {boolean}
   * @memberof IProps
   */
  reuseMaps?: boolean
  controls?: boolean
  onClick?: EnhancedMouseEventFunc
  onMouseDown?: EnhancedMouseEventFunc
  onHover?: EnhancedMouseEventFunc
}

// http://uber-common.github.io/viewport-mercator-project/#/documentation/api-reference/webmercatorviewport
interface FitBoundsOptions {
  padding?: Padding
  offset?: [number, number]
  pitch?: number
}

// UK view
export const defaultViewport = {
  latitude: 54.72814098390994,
  longitude: -3.578428221023512,
  // default zoom
  zoom: 5.35,
  bearing: 0,
  pitch: 0,
  transitionDuration: 350,
  transitionInterpolator: new FlyToInterpolator()
}

const Map: React.FC<IProps> = React.memo(props => {
  const [loaded, setLoaded] = React.useState<boolean>()
  const map = React.useRef<mapboxgl.Map | undefined>()
  const { initialViewport, width, height } = props

  const [state, setState] = React.useState({
    width: width || 500,
    height: height || 500
  })

  const [viewport, setViewport] = React.useState<Viewport>({
    ...defaultViewport,
    ...initialViewport
  })

  React.useEffect(() => {
    updateViewport()
  }, [])

  const getMapObject = React.useCallback(() => {
    if (!map.current || !(map.current as any)._loaded) return false
    return map.current
  }, [map])

  // Event camera controls

  React.useEffect(() => {
    updateViewportDimensions()
  }, [props.width, props.height])

  const updateViewportDimensions = React.useCallback(() => {
    if (width > 100 && height > 100) {
      setState({ width, height })
    }
  }, [setState, width, height])

  const prevProps = usePrevious(props)
  const wasLoaded = usePrevious(loaded)

  React.useEffect(() => {
    if ((loaded && !wasLoaded) || !isEqual(prevProps, props)) {
      updateViewport(props)
    }
  }, [props, loaded, wasLoaded])

  const updateViewport = (p: IProps = props) => {
    updateViewportBounds(
      minBboxSize(
        p.bounds || featuresToBounds(p.fitFeatures || []),
        props.minSize || [0, 0]
      ),
      p.fitBoundsOptions,
      p.fitBoundsOverrides
    )
  }

  const updateViewportBounds = (
    bounds: BBox | null = null,
    options: FitBoundsOptions = { pitch: defaultViewport.pitch },
    viewportRestrictions: FitFeaturesOverrides = (prev, next) => ({})
  ) => {
    if (!bounds || !getMapObject()) return
    const { width, height } = state
    const viewpointFactory = new WebMercatorViewport({
      ...viewport,
      width,
      height,
      pitch: options.pitch || 0
    })

    const nextViewport = viewpointFactory.fitBounds(
      bboxToPointset(bounds),
      options
    )

    setViewport({
      ...viewport,
      ...nextViewport,
      ...viewportRestrictions(viewport, nextViewport)
    })
  }

  const scaleFromDepth = ([longitude, latFeature]: [number, number]) => {
    const map = getMapObject()
    const pitch = viewport.pitch || 0
    //
    const max = 90
    const min = 0
    //
    if (!latFeature || !map || pitch < 5) return 1
    try {
      const mapBounds = map.getBounds()
      // Gives total coordinate height
      const latNorth = mapBounds.getNorth()
      const latSouth = mapBounds.getSouth()
      const latCenter = mapBounds.getCenter().lat
      // Get the range from the center to the edge
      const rangeLat = latNorth - latCenter
      // Subset height of the distance
      const offsetLat = latCenter - latFeature
      const relativeDepth = offsetLat / rangeLat
      // Apply depth to pitch.
      // For instance, at pitch 0 (flat map), the map has no actual depth so scale should be constant 1
      const perspective = Math.min(1, Math.max(0, (pitch || 0) / max))
      const relativeScale = relativeDepth * perspective * 0.66
      return Math.min(2, Math.max(0, relativeScale + 1))
    } catch (e) {
      console.warn(e)
      return 1
    }
  }

  const getMapEventFeature = (e: PointerEvent) => {
    const map = getMapObject()
    if (!map) return []
    try {
      return map.queryRenderedFeatures(e.point) || []
    } catch (e) {
      return []
    }
  }

  const onViewportChange = (viewport: ViewState) => {
    setViewport(viewport)
  }

  const listeners = React.useRef<Array<[MapEvent, EnhancedMouseEventFunc]>>([])

  const addListener: IMapHelpers['addListener'] = (on, cb) => {
    listeners.current.push([on, cb])
  }

  const removeListener: IMapHelpers['removeListener'] = (on, cb) => {
    listeners.current = listeners.current.filter(
      ([_n, _cb]) => on !== _n && cb !== _cb
    )
  }

  // TODO: Make this DRY
  const getListenersForProp = (hook: string) =>
    listeners.current.filter(([on, _]) => on === hook).map(([_, cb]) => cb)

  const onMouseDown: MouseEventFunc = (e): undefined => {
    const l = getListenersForProp('onMouseDown')
    if (!props.onMouseDown && !l.length) return
    const features = getMapEventFeature(e)
    props.onMouseDown && props.onMouseDown(e, e.lngLat, features)
    l.forEach(cb => cb(e, e.lngLat, features))
  }

  const onClick: MouseEventFunc = (e): undefined => {
    const l = getListenersForProp('onClick')
    if (!props.onClick && !l.length) return
    const features = getMapEventFeature(e)
    props.onClick && props.onClick(e, e.lngLat, features)
    l.forEach(cb => cb(e, e.lngLat, features))
  }

  const onHover: MouseEventFunc = (e): undefined => {
    const l = getListenersForProp('onHover')
    if (!props.onHover && !l.length) return
    const features = getMapEventFeature(e)
    props.onHover && props.onHover(e, e.lngLat, features)
    l.forEach(cb => cb(e, e.lngLat, features))
  }

  const { children } = props

  const mapHelper = {
    state,
    viewport,
    getMapObject: getMapObject,
    updateViewportBounds: updateViewportBounds,
    scaleFromDepth: scaleFromDepth,
    getMapEventFeature: getMapEventFeature,
    addListener,
    removeListener
  }

  // Pass on the mapbox instance to react-mapbox-gl children
  const getMap = (_map: MapGL | null) => {
    if (!_map) return

    map.current = _map.getMap()

    map.current.on('load', () => {
      if (map.current) {
        setLoaded(true)
      }
    })
  }

  return (
    <MapGL
      {...state}
      {...props}
      {...viewport}
      mapStyle={props.mapboxStyleUrl}
      mapboxApiAccessToken={props.mapboxToken}
      width={width}
      height={height}
      ref={getMap}
      onViewportChange={onViewportChange}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onHover={onHover}
      reuseMaps={true}
    >
      <MapContext.Provider value={map.current}>
        {loaded && (
          <MapViewportContext.Provider value={{ ...viewport, ...state }}>
            <MapHelperContext.Provider value={{ mapHelper }}>
              {children}
              <ZoomControl
                position="bottom-left"
                sx={{
                  position: 'absolute !important',
                  bottom: '30px !important',
                  left: '30px !important'
                }}
              />
            </MapHelperContext.Provider>
          </MapViewportContext.Provider>
        )}
      </MapContext.Provider>
    </MapGL>
  )
})

export const MapViewportContext = React.createContext<
  Viewport & { width: number; height: number }
>({} as any)

export const useMapViewport = () => React.useContext(MapViewportContext)

Map.defaultProps = {
  controls: true,
  reuseMaps: true,
  mapboxStyleUrl: `mapbox://styles/${process.env.MAPBOX_USERNAME}/${
    process.env.MAPBOX_STYLE_ID
  }${process.env.NODE_ENV === 'development' ? '/draft' : ''}`,
  mapboxToken: process.env.MAPBOX_ACCESS_TOKEN
}

export default Map
