/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import useWindowSize from '@rehooks/window-size'
import { ParentSize } from '@vx/responsive'
import Map from 'map/Map'
import { UKBounds } from 'data/geo'
import { useRouteMatch } from 'react-router'
import { Event } from 'routes'
import { usePushRoute } from 'components/Navigation'
import {
  recommendationType,
  RecommendationType
} from 'components/PostcodeRecommendations'
import CommunicationGroupGeo from 'event/CommunicationGroupGeo'
import EventGeo from 'event/EventGeo'
import { tuple } from 'helpers/array'
import MapMarginalityLayers from 'map/MapMarginalConstituencies'
import { isUUID } from 'helpers/string'
import {
  useCategorisedEvents,
  usePostcodeRecommendations,
  useEvent,
  useConstituencyScreenData,
  useAllEvents
} from '../views/queries'

const CONSTITUENCY_MIN_SIZE = tuple(0.1, 0.1)
const WARD_MIN_SIZE = tuple(0.05, 0.05)

interface ScreenConfigProps {
  key: string
  minSize: [number, number]
  featureLocations: GeoJSONFeature[]
  features: React.ReactNode
  pitch?: number
  padding?: number
}

type ScreenConfig = () => ScreenConfigProps | undefined

const useLandingScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{ postcode: string }>({ path: '/', exact: true })

  if (route) {
    return DEFAULT_SCREEN_CONFIG
  }

  return undefined
}

const eventsLayer = (data: any, key: string) => {
  const pushEvent = usePushRoute(Event)
  if (data) {
    if (data.events.length === 0) {
      return DEFAULT_SCREEN_CONFIG
    }

    return {
      key: key,
      minSize: CONSTITUENCY_MIN_SIZE,
      padding: 100,
      featureLocations: data.events,
      features: (
        <React.Fragment>
          {data.events.map(e => (
            <EventGeo
              key={e.id}
              onClick={() => {
                pushEvent(`/event/${e.id}`)
              }}
              {...e}
            />
          ))}
        </React.Fragment>
      )
    }
  }

  return undefined
}

const groupsLayer = (data: any, key: string) => {
  if (data) {
    if (data.groups.length === 0) {
      return DEFAULT_SCREEN_CONFIG
    }
    const resultMarker = (
      <React.Fragment>
        {data.groups.map(g => (
          <CommunicationGroupGeo key={g.id} {...g} />
        ))}
      </React.Fragment>
    )
    return {
      key: key,
      minSize: CONSTITUENCY_MIN_SIZE,
      padding: 100,
      featureLocations: data.groups,
      features: resultMarker
    }
  }

  return undefined
}

const useAllEventsScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{
    date: string
    postcode: string
    radius: string
  }>('/allevents/:date/:postcode/:radius')

  const key = JSON.stringify([
    'events',
    {
      filters: route && {
        postcode: route.params.postcode,
        date: route.params.date,
        radius: route.params.radius
      }
    }
  ])

  const { data } = route
    ? useAllEvents(
        route.params.postcode,
        route.params.date,
        parseInt(route.params.radius)
      )
    : useAllEvents(undefined, undefined)

  return eventsLayer(data, key)
}

const useCategorisedEventsScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{
    category: string
  }>('/events/:category')

  if (!route) {
    return undefined
  }

  const key = JSON.stringify([
    'events',
    {
      filters: route && {
        category: route.params.category
      }
    }
  ])

  const { data } = route
    ? useCategorisedEvents(route.params.category)
    : useCategorisedEvents('campaigning')

  return eventsLayer(data, key)
}

const useRecommendationsScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{ postcode: string }>(
    '/recommendations/:postcode'
  )

  const { data } = usePostcodeRecommendations(
    route ? route.params.postcode : undefined
  )

  const key = JSON.stringify([
    'recommendations',
    { postcode: route && route.params.postcode }
  ])

  return recommendationType === RecommendationType.Groups
    ? groupsLayer(data, key)
    : eventsLayer(data, key)
}

const useEventScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{ id: string }>('/event/:id')

  const { data } = useEvent(
    route && isUUID(route.params.id) ? route.params.id : undefined
  )

  if (data && data.event) {
    const { id } = data.event

    return {
      key: JSON.stringify(['event', { id }]),
      minSize: WARD_MIN_SIZE,
      featureLocations: [data.event],
      features: (
        <React.Fragment>
          <EventGeo key={id} exact={true} {...data.event} />
        </React.Fragment>
      )
    }
  }

  return undefined
}

const useCreateEventScreenFeatures: ScreenConfig = () => {
  const route = useRouteMatch<{ id: string }>('/create-event')
  if (route) {
    return DEFAULT_SCREEN_CONFIG
  }

  return undefined
}

const useConstituencyScreenFeatures: ScreenConfig = () => {
  const pushEvent = usePushRoute(Event)
  const route = useRouteMatch<{ id: string }>('/constituency/:id')

  const { data } = useConstituencyScreenData(
    route ? route.params.id : undefined
  )

  if (data && data.constituency) {
    if (data.constituency.upcomingEvents.length === 0) {
      return DEFAULT_SCREEN_CONFIG
    }

    const { id } = data.constituency

    return {
      key: JSON.stringify(['constituency', { id }]),
      minSize: CONSTITUENCY_MIN_SIZE,
      featureLocations: data.constituency.upcomingEvents,
      features: (
        <React.Fragment>
          <MapMarginalityLayers navigateOnClick />
          {data.constituency.upcomingEvents.map(e => (
            <EventGeo
              key={e.id}
              onClick={event => {
                pushEvent(`/event/${e.id}`)
              }}
              {...e}
            />
          ))}
        </React.Fragment>
      )
    }
  }

  return undefined
}

/**
 * Given a list of configs, return the one that is active _and_ loaded,
 * staying on the previous if the new one hasn't loaded yet
 */
const useSelectScreenConfig = (configs: (ScreenConfigProps | undefined)[]) => {
  const [activeConfig, setActiveScreenConfig] = React.useState<
    ScreenConfigProps
  >(DEFAULT_SCREEN_CONFIG)

  const requestedConfig = configs.find(screen => !!screen)

  React.useEffect(() => {
    if (requestedConfig && requestedConfig.featureLocations) {
      setActiveScreenConfig(requestedConfig)
    }
  }, [requestedConfig && requestedConfig.key])

  return activeConfig
}

const DEFAULT_SCREEN_CONFIG = {
  key: 'default',
  featureLocations: [UKBounds],
  features: [],
  minSize: tuple(10, 10)
}

const BackgroundMap: React.FC = () => {
  const { innerHeight } = useWindowSize()
  const {
    minSize,
    featureLocations,
    features,
    pitch,
    padding
  } = useSelectScreenConfig([
    useRecommendationsScreenFeatures(),
    useCreateEventScreenFeatures(),
    useEventScreenFeatures(),
    useLandingScreenFeatures(),
    useAllEventsScreenFeatures(),
    useCategorisedEventsScreenFeatures()
  ])

  return (
    <ParentSize>
      {parent => (
        <Map
          key="parent"
          width={parent.width}
          height={innerHeight}
          minSize={minSize}
          fitBoundsOptions={{ pitch, padding }}
          fitFeatures={featureLocations}
        >
          {features}
        </Map>
      )}
    </ParentSize>
  )
}

export default BackgroundMap
