import { createContext, useContext } from 'react'
import { BrowserClient, Hub } from '@sentry/browser'
// import mixpanel, { Mixpanel } from 'mixpanel-browser'
import { noop } from 'lodash'
import googleAnalytics from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

const advancedMatching = process.env.FACBOOK_PIXEL_MATCHING
  ? JSON.parse(process.env.FACBOOK_PIXEL_MATCHING)
  : undefined

export const initialiseUserTracking = () => {
  ReactPixel.init(process.env.FACEBOOK_TRACKING_ID, advancedMatching, {
    autoConfig: true,
    debug: process.env.NODE_ENV === 'development'
  })

  googleAnalytics.initialize(process.env.GOOGLE_ANALYTICS_ID!, {
    debug: process.env.NODE_ENV === 'development'
  })
}

export const initialiseErrorTracking = () => {
  // Client-side error logging to Sentry.io: https://sentry.io/organizations/futrai/issues/?project=1425304
  const sentryClient = new BrowserClient({
    beforeSend: (event: any) =>
      process.env.NODE_ENV === 'production' ? event : null,
    environment: process.env.NODE_ENV,
    release: process.env.LAST_GIT_COMMIT,
    dsn: process.env.SENTRY_DSN,
    blacklistUrls: [/ngrok\.io/, /https?:\/\/localhost/]
  })

  const Sentry = new Hub(sentryClient)

  return Sentry
}

export const initialiseAnalytics = (dev?: boolean): Analytics => {
  // Track user events
  initialiseUserTracking()

  // Track errors
  // const sentry = initialiseErrorTracking()
  const sentry = {
    configureScope: () => {},
    captureException: () => {},
  }

  const logView: Analytics['logView'] = dev
    ? noop
    : (path, context) => {
        googleAnalytics.set({ page: path })
        googleAnalytics.pageview(path)
      }

  logView(location.pathname)

  return {
    initialiseCookies: dev ? noop : () => {},
    setUserId: dev
      ? noop
      : id => {
          //   mixpanel.identify(email || id)
          googleAnalytics.set({ userId: id })
          sentry.configureScope(scope => {
            scope.setUser({ id })
          })
        },
    setContext: dev
      ? noop
      : context => {
          //   mixpanel.register(context)
          sentry.configureScope(scope => {
            scope.setUser(context)
          })
        },
    logView,
    logEvent: dev
      ? noop
      : (event, context = {}) => {
          ReactPixel.track(event, context)

          googleAnalytics.event({
            category: context.category || 'User',
            action: event,
            label: context.value,
            value: context.metric as any
          })
        },
    logError: error => sentry.captureException(error)
  }
}

/**
 * Hooks
 */

export const analytics = initialiseAnalytics(
  process.env.NODE_ENV !== 'production'
)
const AnalyticsContext = createContext<Analytics>({
  logError: noop,
  logEvent: noop,
  logView: noop,
  initialiseCookies: noop,
  setContext: noop,
  setUserId: noop
})
export const AnalyticsProvider = AnalyticsContext.Provider
export const AnalyticsConsumer = AnalyticsContext.Consumer
export const useAnalytics = () => useContext(AnalyticsContext)
