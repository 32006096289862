import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { authorise, register, tokenIsValid } from 'auth/authHelpers'
import { patchLocalState, createUpdateTokenAction } from 'data/state'
import { JWT_STORAGE_KEY, FEATURE_FLAGS } from 'symbols'
import AuthModal, { AuthMode } from 'auth/AuthModal'
import { get, noop, uniqueId } from 'lodash'
import { useLocation } from 'react-router'
import { tuple } from 'helpers/array'
import { RegistrationParams } from 'auth/RegisterForm'
import { parse } from 'querystring'
import { CurrentUser_currentUser } from './__graphql__/CurrentUser'

export const QUERY_USER = gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      lastName
      email
      phone
      isContactable
      isPublic
    }
  }
`

export const useCurrentUser = () => {
  const { state } = useAuth()
  const query = useQuery<{ currentUser: CurrentUser_currentUser }>(QUERY_USER)
  React.useEffect(() => void query.refetch(), [state])
  const user: CurrentUser_currentUser | null = get(query, 'data.currentUser')
  return { user, ...query }
}

export const usePostcode = () => {
  return React.useMemo(() => localStorage.getItem('postcode'), [])
}

export const useFeatureFlag = (flag: string) => {
  const location = useLocation()

  if (FEATURE_FLAGS[flag]) {
    return true
  }

  return !!parse(location.search.substr(1)).test
}

export const useSavePostcode = (postcode: string) => {
  return React.useEffect(() => localStorage.setItem('postcode', postcode), [
    postcode
  ])
}

const TOKEN_QUERY = gql`
  query Token {
    token @client
  }
`

const authEvents = new Set<() => void>()

export const notifyAuthChange = () => {
  for (const cb of authEvents) {
    cb()
  }
}

export const useAuth = () => {
  const [_, setKey] = React.useState(uniqueId('auth'))
  React.useEffect(() => {
    const cb = () => {
      // force update yeeach
      setKey(uniqueId('auth'))
    }

    authEvents.add(cb)

    return () => {
      authEvents.delete(cb)
    }
  }, [])

  const state = useQuery<{ token?: string }>(TOKEN_QUERY)
  const set = React.useCallback(async token => {
    return patchLocalState(createUpdateTokenAction(token))
  }, [])
  return {
    set,
    state,
    loggedIn: Boolean(localStorage.getItem(JWT_STORAGE_KEY)),
    register,
    authorise,
    tokenIsValid: () => {
      const token = localStorage.getItem(JWT_STORAGE_KEY)
      return token && tokenIsValid(token)
    }
  }
}

export const useLocalStorage = (key: string) => {
  const [state, setState] = React.useState(localStorage.getItem(key))
  return tuple(state, (value: string) => {
    setState(value)
    localStorage.setItem(key, value)
  })
}

interface AuthOpts {
  mode?: AuthMode
  onAuthenticated?: () => void
  initialValues?: Partial<RegistrationParams>
}

/**
 * This hook concretises the 'delay user registration until the last possible moment'
 * principle of product design, on the basis that uncessary registration, form filling and information sharing
 * is a serious barrier to engagement.
 *
 * Use this in cojunction with clear explainers as to why the required data
 * (in this case, authentication) is necessary and assurances as to how the data will be used and stored.
 *
 * TODO: Generalise the `test`, `updateTestResults` and `renderProp when test failed` components of this hook
 *          so it could be used for other state-dependent purposes (e.g. time-based)
 */
export const useAuthenticatedCallback = () => {
  const jwt = useAuth()
  const [showAuthFlowMode, setShowAuthFlowMode] = React.useState<AuthMode>()
  const [open, setOpen] = React.useState(true)
  const cachedRestrictedCb = React.useRef<() => void>()
  const [initialAuthValues, setInitialAuthValues] = React.useState<
    RegistrationParams
  >()

  const callbackAfterAuthentication = async ({
    mode = 'register',
    onAuthenticated = noop,
    initialValues
  }: AuthOpts) => {
    setInitialAuthValues(initialValues)

    if (jwt.loggedIn) {
      onAuthenticated()
      cachedRestrictedCb.current = undefined
    } else {
      cachedRestrictedCb.current = onAuthenticated
      setShowAuthFlowMode(mode)
      setOpen(true)
    }

    notifyAuthChange()
  }

  /**
   * The way this works is that `JWT` will re-render
   * whenever the sign-in query runs and the token property
   * in the graphql cache updates - hence JWT in the cache array.
   */
  React.useEffect(() => {
    ;(async () => {
      const isValid = await jwt.tokenIsValid()
      if (isValid) {
        setOpen(false)
      }
    })()
  }, [cachedRestrictedCb, jwt, setShowAuthFlowMode])

  const onAuthentication = () => {
    setOpen(false)
    cachedRestrictedCb.current && cachedRestrictedCb.current()
    cachedRestrictedCb.current = undefined
  }

  return tuple(
    callbackAfterAuthentication,
    <AuthModal
      mode={showAuthFlowMode}
      open={
        open &&
        !!showAuthFlowMode &&
        typeof cachedRestrictedCb.current === 'function'
      }
      setMode={setShowAuthFlowMode}
      onClose={() => setOpen(false)}
      onAuthentication={onAuthentication}
      initialValues={initialAuthValues}
    />
  )
}
