import gql from 'graphql-tag'

export const EventCategoryFragment = gql`
  fragment EventCategoryFragment on EventProperties {
    category {
      id
      name
      emoji
      description
      userVisible
      allowUserCreate
    }
  }
`

export const EventSubcategoryFragment = gql`
  fragment EventSubcategoryFragment on EventProperties {
    subcategory {
      id
      name
      emoji
      description
      userVisible
      allowUserCreate
    }
  }
`
