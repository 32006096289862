/** @jsx jsx */

import { jsx } from 'theme-ui'
import { useField, Field } from 'react-jeff'
import React, { useEffect, useState, Fragment } from 'react'
import gql from 'graphql-tag'
import { Event, AllEvents } from 'routes'
import { toggle } from 'helpers/array'
import { getCategory } from 'data/event'
import { groupByDay, DateTimeFormat } from 'helpers/date'
import { sortBy, isEqual } from 'lodash'
import { isToday } from 'date-fns/esm'
import { useMakeCommitment } from 'views/Commitment'
import { useFeatureFlag } from 'data/hooks'
import { EventRecommendation } from '__graphql__/EventRecommendation'
import { AddButton } from '../components/AddButton'
import {
  EventCategoryFragment,
  EventSubcategoryFragment
} from './EventFragments'
import { PushLink } from '../components/Navigation'
import { PanelCard, LoadingArea, PanelCardTitle } from '../components/Elements'
import { DateTimeValue } from '../components/DateField'
import { WhatsAppJoinButton } from '../components/WhatsAppJoinButton'
import { CatOrSubcat } from 'views/queries'

export const EventRecommendationsList: ComponentWithFragment<{
  recommendations: EventRecommendation[]
  subcategories: CatOrSubcat[]
  eventsPerDay?: number
  currentPostcode?: string
  loading?: boolean
  isFilterable?: boolean
  loadingMessage?: string
}> = ({
  recommendations,
  subcategories,
  eventsPerDay,
  currentPostcode,
  loading,
  isFilterable = true,
  loadingMessage = 'Finding events closest to you',
  ...props
}) => {
  const [makeCommitment, makeCommitmentModal] = useMakeCommitment()

  const visibleSubcats = subcategories.filter(s => s.userVisible)
  const subcategoryNames = visibleSubcats.map(s => s.name).sort()
  const [filteredRecommendations, setFilteredRecommendations] = useState(
    recommendations
  )

  const categoryFilter = useField<string[]>({
    defaultValue: subcategoryNames
  })

  useEffect(() => {
    const categoriesChanges =
      subcategoryNames.length !== categoryFilter.value.length ||
      !subcategoryNames.every(x => categoryFilter.value.includes(x))
    if (categoriesChanges) {
      categoryFilter.setValue(subcategoryNames)
    }
  }, [subcategories])

  useEffect(() => {
    const newlyFilteredRecs = recommendations.filter(e => {
      const properties = e.properties!
      return (
        categoryFilter.value.includes(properties.category.name) ||
        (properties.subcategory &&
          categoryFilter.value.includes(properties.subcategory.name))
      )
    })
    if (!isEqual(newlyFilteredRecs, filteredRecommendations)) {
      console.log(`Now ${newlyFilteredRecs.length} events will be shown`)
      setFilteredRecommendations(newlyFilteredRecs)
    }
  }, [categoryFilter])

  return (
    <React.Fragment>
      <PanelCard
        sep
        sx={{
          bg: 'primary',
          borderBottom: '1px solid grey',
          borderBottomColor: 'gray.2'
        }}
      >
        <PanelCardTitle
          sx={{
            color: 'white',
            variant: 'columns',
            alignItems: 'center',
            mb: 0
          }}
        >
          <span sx={{ fontSize: [4, 5] }}>Nearby events</span>
        </PanelCardTitle>
      </PanelCard>
      {makeCommitmentModal}
      {isFilterable && (
        <PanelCard
          {...props}
          sep
          sx={{
            variant: 'columns',
            alignItems: 'center',
            py: 2,
            overflowX: 'auto',
            bg: 'contrast.0'
          }}
        >
          <div sx={{ variant: 'caption', fontWeight: 600, mr: 2 }}>
            Showing:
          </div>
          {visibleSubcats.map(c => (
            <OptionChip
              key={`${c.id}-${c.level}`}
              field={categoryFilter}
              value={c.name}
            >
              {c.name}
            </OptionChip>
          ))}
        </PanelCard>
      )}

      <EventListByDay<EventRecommendation>
        values={filteredRecommendations}
        loading={loading}
        loadingMessage={loadingMessage}
        onRsvp={makeCommitment}
        eventsPerDay={eventsPerDay}
        currentPostcode={currentPostcode}
      ></EventListByDay>
    </React.Fragment>
  )
}

const OptionChip: ViewElement<{ value: string; field: Field<string[]> }> = ({
  value,
  field,
  children,
  ...props
}) => (
  <button
    {...props}
    onClick={() => field.setValue(toggle(field.value, value))}
    sx={{
      variant: 'button',
      cursor: 'pointer',
      userSelect: 'none',
      py: 1,
      px: 3,
      mr: 1,
      borderRadius: 100,
      bg: field.value.includes(value) ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)'
    }}
  >
    <span sx={{ variant: 'caption', fontWeight: 600 }}>{children}</span>
  </button>
)

export const EventListByDay: ViewElementRenderer<
  {
    loading?: boolean
    loadingMessage?: string
    onRsvp?: (props: { eventId: string }) => void
    eventsPerDay?: number
    currentPostcode?: string
  },
  {
    id: string
    properties: {
      startTime: string
      amAttending?: boolean
      name: string
      straightLineDistance?: number | null
      category: string
      subcategory?: string | null
      constituency?: {
        name: string
      }
    } | null
  }
> = ({
  loading,
  loadingMessage,
  values,
  children,
  onRsvp,
  eventsPerDay,
  currentPostcode,
  ...props
}) => {
  const showAllEvents = useFeatureFlag('all-events')

  if (loading) {
    return <LoadingArea>{loadingMessage}</LoadingArea>
  }

  if (values.length === 0) {
    return (
      <React.Fragment>
        <PanelCard
          sep="light"
          sx={{
            py: 3,
            bg: 'white',
            display: 'grid',
            alignContent: 'center'
          }}
          {...props}
        >
          <PanelCardTitle sx={{ textAlign: 'center' }}>
            No events found here.
          </PanelCardTitle>
        </PanelCard>
        <AddButton />
      </React.Fragment>
    )
  }

  const eventsByDay = groupByDay(
    values,
    'Europe/London',
    x => x.properties!.startTime
  )

  return (
    <div>
      {eventsByDay.map(({ day, values: events }, i) => (
        <React.Fragment key={day}>
          <PanelCard
            sep
            sx={{
              variant: 'caption',
              fontWeight: 'bold',
              position: 'sticky',
              bg: 'contrast.0',
              top: 0,
              zIndex: 10 + i,
              py: 2
            }}
          >
            <DateTimeValue
              sx={{ color: isToday(new Date(day)) ? 'primary' : 'initial' }}
              relative
              value={day}
              format={DateTimeFormat.DAY_LONG}
            />
          </PanelCard>

          <EventList events={sortBy(events, e => e.properties!.startTime)} />

          {currentPostcode && showAllEvents && (
            <PushLink
              route={AllEvents}
              sx={{ variant: 'boxLink', color: 'primary' }}
              to={`/allevents/${day}/${currentPostcode}/40`}
            >
              <PanelCard
                key={'all events'}
                sep
                bg="white"
                sx={{
                  py: 2,
                  pb: 3,
                  borderBottom: '1px solid grey',
                  borderBottomColor: 'gray.2'
                }}
              >
                <div sx={{ mt: 2 }}>
                  More events on{' '}
                  <DateTimeValue value={day} format={DateTimeFormat.DATE_S} />{' '}
                  &rarr;
                </div>
              </PanelCard>
            </PushLink>
          )}
        </React.Fragment>
      ))}
      <AddButton sep />
    </div>
  )
}

const add2Hours = (startTime: string) => {
  const startDate = new Date(startTime)
  return new Date(startDate.getTime() + 2 * 60 * 60 * 1000).toISOString()
}

export const EventList: React.FC<{ events: EventRecommendation[] }> = ({
  events
}) => {
  const hasLowPriorityCenters = events.some(
    e => e.properties!.labourPriority !== 3
  )

  return (
    <Fragment>
      {events.map(item => {
        const category = getCategory(item.properties!)
        const endTime =
          item.properties.endTime || add2Hours(item.properties.startTime)
        const distance = item.properties!.straightLineDistance
          ? `${item.properties!.straightLineDistance.toFixed(1)} km away`
          : ''
        return (
          <PushLink
            route={Event}
            sx={{ variant: 'boxLink', color: 'primary' }}
            key={item.id}
            to={`/event/${item.id}`}
          >
            <PanelCard
              sep
              bg="white"
              sx={{
                py: 3,
                borderBottom: '1px solid grey',
                borderBottomColor: 'gray.2',
                bg:
                  hasLowPriorityCenters && item.properties!.labourPriority === 3
                    ? '#fff7e2'
                    : 'white'
              }}
            >
              <PanelCardTitle sx={{ color: 'black' }}>
                <span sx={{ fontSize: [4, 5] }}>
                  {(item.properties!.labourPriority === 3 &&
                    category.altEmoji) ||
                    category.emoji}{' '}
                  {item.properties!.name}
                </span>
              </PanelCardTitle>

              <div
                sx={{
                  variant: 'columns',
                  alignItems: 'flex-start'
                }}
              >
                <div sx={{ flex: 1 }}>
                  <div sx={{ variant: 'caption', mb: 1, fontWeight: 'bold' }}>
                    {category.name}
                  </div>

                  <div
                    sx={{
                      variant: 'caption'
                    }}
                  >
                    <DateTimeValue
                      sx={{
                        color: 'primary',
                        textTransform: 'lowercase',
                        fontWeight: 'bold'
                      }}
                      value={item.properties!.startTime}
                      format={DateTimeFormat.TIME}
                    />
                    <React.Fragment>
                      {' to '}
                      <DateTimeValue
                        sx={{
                          color: 'primary',
                          textTransform: 'lowercase',
                          fontWeight: 'bold',
                          mr: 2
                        }}
                        value={endTime}
                        format={DateTimeFormat.TIME}
                      />
                    </React.Fragment>
                    {distance}
                  </div>

                  {item.properties!.constituency && (
                    <div
                      sx={{
                        mt: 2,
                        variant: 'description',
                        fontWeight: 'bold'
                      }}
                    >
                      {item.properties!.constituency.name}
                    </div>
                  )}
                </div>

                <div
                  sx={{
                    variant: 'rows',
                    alignSelf: 'stretch',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    ml: 2
                  }}
                >
                  <div sx={{ variant: 'columns', alignItems: 'center' }}>
                    {item.properties!.whatsappLink && (
                      <WhatsAppJoinButton
                        small
                        name={item.properties!.name}
                        href={item.properties!.whatsappLink}
                      />
                    )}
                  </div>

                  <div sx={{ mt: 2 }}>More information &rarr;</div>
                </div>
              </div>
            </PanelCard>
          </PushLink>
        )
      })}
    </Fragment>
  )
}

EventRecommendationsList.fragment = gql`
  ${EventCategoryFragment}
  ${EventSubcategoryFragment}

  fragment EventRecommendation on EventType {
    id
    properties {
      name
      constituency {
        id
        name
        volunteerNeedBand
      }
      startTime
      endTime
      address
      ...EventCategoryFragment
      ...EventSubcategoryFragment
      labourPriority
      whatsappLink
      isFull
      amAttending
    }
  }
`
