/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Layer } from 'react-mapbox-gl'
import { usePushRoute } from 'components/Navigation'
import { Constituency } from 'routes'
import { useMapEventHandlers } from 'map/MapHelperContext'
import {
  CONSTITUENCY_ID_PROPERTY,
  CONSTITUENCY_SOURCE_LAYER_ID,
  CONSTITUENCY_MAP_SOURCE,
  CONSTITUENCY_NAME_PROPERTY
} from '../helpers/geo'
import {
  NationalMap,
  NationalMap_constituencies
} from '../commitments/__graphql__/NationalMap'

const CONSTITUENCIES_MARGINALITY_QUERY = gql`
  query NationalMap {
    constituencies {
      id
    }
  }
`

const MapMarginalityLayers: React.FC<{ navigateOnClick?: boolean }> = ({
  navigateOnClick
}) => {
  const navigateConstituency = usePushRoute(Constituency)
  const { data } = useQuery<NationalMap>(CONSTITUENCIES_MARGINALITY_QUERY)

  useMapEventHandlers({
    onClick: (e, lnglat, features) => {
      if (!navigateOnClick) {
        return
      }

      const constituency = features.find(
        f => f.sourceLayer === CONSTITUENCY_SOURCE_LAYER_ID
      )
      if (!constituency || !constituency.properties) {
        return
      }

      const id = constituency.properties[CONSTITUENCY_ID_PROPERTY]
      if (!id) {
        return
      }

      console.log('constit')
      navigateConstituency(`/constituency/${id}`)
    }
  })

  if (!data || !data.constituencies) {
    return null
  }

  return <MapMarginalityLayerContent constituencies={data.constituencies} />
}

export default MapMarginalityLayers

interface Constituency {
  id: string
  electionSet: Election[]
}

interface Election {
  date: string
  winningParty: string
  labourMarginality: number
}

const MapMarginalityLayerContent: React.FC<{
  constituencies: NationalMap_constituencies[]
}> = ({ constituencies }) => (
  <React.Fragment>
    {/* Constituency borders */}
    <Layer
      key={`constituency-lines`}
      id={`constituency-lines`}
      {...CONSTITUENCY_MAP_SOURCE}
      before="road-label"
      type="line"
      paint={{
        'line-color': 'black',
        'line-opacity': 0.25,
        'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 20, 10]
      }}
      layout={{
        'line-cap': 'round',
        'line-join': 'round'
      }}
    />
    {/* Constituency names */}
    <Layer
      key={`constituency-names`}
      id={`constituency-names`}
      {...CONSTITUENCY_MAP_SOURCE}
      before="country-label"
      type="symbol"
      paint={{
        'text-color': 'black',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 1.5,
        'text-opacity': 0.5
      }}
      layout={{
        'text-field': ['get', CONSTITUENCY_NAME_PROPERTY],
        'text-font': ['Asap Bold'],
        'text-size': 10,
        'text-padding': ['interpolate', ['linear'], ['zoom'], 0, 50, 12, 10]
      }}
    />
  </React.Fragment>
)
