import {
  addDays,
  setDay,
  formatRelative as _formatRelative,
  isAfter,
  subHours,
  startOfDay
} from 'date-fns'
import { format, toDate } from 'date-fns-tz'
import { enGB } from 'date-fns/locale'
import { groupBy, sortBy } from 'lodash'
import { isSameDay } from 'date-fns'

export enum DateTimeFormat {
  ISO_DATE = 'yyyy-MM-dd',
  TIME = 'h:mma',
  TIME_NO_AMPM = 'h:mm',
  DAY = 'dd',
  DAY_LONG = 'EEEE do LLLL',
  WEEKDAY_S = 'EEE',
  DATE_S = 'd LLL',
  MONTH_SHORT = 'MMM'
}

export const formatRelative = (date: Date, baseDate: Date = new Date()) =>
  _formatRelative(date, baseDate, { locale: enGB })

export const isAttendable = (date: Date | number) =>
  isAfter(new Date(date), subHours(startOfDay(new Date()), 1))

export const groupByDay = <T>(
  xs: T[],
  timezone: string,
  getDate: (x: T) => Date | string
) => {
  const byDay = groupBy(xs, x =>
    format(toDate(getDate(x)), DateTimeFormat.ISO_DATE, { timeZone: timezone })
  )
  const ordered = Object.entries(byDay).map(([day, values]) => ({
    day,
    values
  }))
  return sortBy(ordered, x => x.day)
}

export const electionDay = new Date(2019, 11, 12)

export const isElectionDay = () => true // isSameDay(new Date(), electionDay)

export const hmsToDate = (targetDate: Date) => {
  const now = new Date()
  const days = Math.floor((targetDate - now) / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (targetDate - now - days * 1000 * 60 * 60 * 24) / (1000 * 60 * 60)
  )
  const minutes = Math.floor(
    (targetDate - now - days * 1000 * 60 * 60 * 24 - hours * 1000 * 60 * 60) /
      (1000 * 60)
  )
  return [days, hours, minutes]
}
