/** @jsx jsx */
/* global Raven */

import { jsx } from 'theme-ui'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  fallback?: null | ((error: any) => any)
  error?: any
}

interface IState {
  hasError: boolean
  error?: any
  info?: any
}

class ErrorBoundary extends Component<IProps, IState> {
  public state = {
    hasError: false,
    error: undefined,
    info: undefined
  }

  public componentDidCatch(error: any, info: any) {
    // console.info('Sending to raven', error, { extra: info })
    // Raven.captureException(error, { extra: info })
    // Display fallback UI
    console.error(error)
    this.setState({ hasError: true, error, info })
  }

  // private reportError = () => Raven.lastEventId() && Raven.showReportDialog()

  hasPostcodeError = () => {
    return this.state.hasError && this.state.error !== undefined
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          sx={{
            variant: 'rows',
            position: 'relative',
            backgroundColor: 'contrast.0',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            px: 3
          }}
        >
          <div
            sx={{
              variant: 'rows',
              position: 'relative',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              sx={{ mb: 3, maxWidth: '100%' }}
              src={require('../images/logos/logo_2x.png')}
            />
            <p sx={{ variant: 'body', fontSize: 6, mb: 1 }}>YIKES</p>
            {this.hasPostcodeError() ? (
              <React.Fragment>
                <p sx={{ variant: 'caption', mb: 0 }}>
                  That didn’t work. It looks like you submitted the wrong
                  postcode. Please <a href="/">click here to try again</a>.
                </p>
                <p sx={{ variant: 'caption', mb: 1 }}>
                  If it still doesn’t work, let us know. You can email{' '}
                  <a href="mailto:info@peoplesmomentum.com">
                    info@peoplesmomentum.com
                  </a>{' '}
                  and we will try our best to help you.
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p sx={{ variant: 'caption', mb: 0 }}>
                  That didn’t work. It might be a problem with your internet
                  connection. Or it might be something on our side.
                </p>
                <p sx={{ variant: 'caption', mb: 1 }}>
                  Try refreshing the page. If it still doesn’t work, let us
                  know. You can email{' '}
                  <a href="mailto:info@peoplesmomentum.com">
                    info@peoplesmomentum.com
                  </a>{' '}
                  and we will try our best to help you.
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
