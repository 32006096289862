import 'react-hot-loader'
import * as React from 'react'
import { render } from 'react-dom'
import { createBrowserHistory } from 'history'
import { analytics } from 'data/analytics'
import { REACT_APP_ROOT } from './symbols'
import { ContextualisedApp } from './index.common'

const history = createBrowserHistory()

analytics.logView(location.pathname)

history.listen(location => {
  analytics.logView(location.pathname)
})

render(
  <ContextualisedApp history={history} />,
  document.getElementById(REACT_APP_ROOT)
)
