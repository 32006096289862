import React, { ReactNode, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import { noop } from 'lodash'
import { tuple } from '../helpers/array'

interface AlertProps {
  message: ReactNode
  title: ReactNode
  cancelLabel?: ReactNode
  confirmLabel?: ReactNode
  onConfirm: () => void
  onCancel?: () => void
}

export const useAlert = ({
  message,
  title,
  cancelLabel = 'Cancel',
  confirmLabel = 'Ok',
  onConfirm = noop,
  onCancel = noop
}: AlertProps) => {
  const [open, setOpen] = useState(false)

  const content = (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={() => {
        setOpen(false)
        onCancel()
      }}
      onClick={event => {
        event.stopPropagation()
        setOpen(false)
        onCancel()
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={event => {
            event.stopPropagation()
            setOpen(false)
            onCancel()
          }}
          color="primary"
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={event => {
            event.stopPropagation()
            onConfirm()
            setOpen(false)
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )

  return tuple(content, () => setOpen(true))
}
