export function capitalise(str: string) {
  const [C, ...c] = Array.from(str)
  return C.toUpperCase() + c.join('')
}

export function isUUID(x: string) {
  return !!x.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  )
}
