import 'react-hot-loader'
import * as React from 'react'
import { Router } from 'react-router-dom'
import GraphQLProvider from 'data/graphql'
import Theme from 'data/theme'
import { createBrowserHistory, History } from 'history'
import { AnalyticsProvider, analytics } from 'data/analytics'
import App from './App'

const history = createBrowserHistory()

analytics.logView(location.pathname)

history.listen(location => {
  analytics.logView(location.pathname)
})

export const ContextualisedApp: React.FC<{ history: History }> = ({
  history
}) => (
  <Theme>
    <AnalyticsProvider value={analytics}>
      <GraphQLProvider>
        <Router history={history}>
          <App />
        </Router>
      </GraphQLProvider>
    </AnalyticsProvider>
  </Theme>
)
