/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { noop } from 'lodash'
import { authorise } from 'auth/authHelpers'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ProgressButton, FormWrapper } from 'components/Elements'
import { Typography } from '@material-ui/core'
import { preventDefault } from 'helpers/dom'
import {
  Input,
  FormSection,
  FormGrid,
  FORM_GENERAL_ERROR,
  FormError
} from '../components/Form'

interface FormFields {
  email: string
  password: string
}

export const SignInForm: React.FC<{
  onAuthentication?: () => void
  onRequestRegistration?: () => void
}> = ({ onAuthentication = noop, onRequestRegistration }) => {
  return (
    <SignInFormContent
      onSubmit={async variables => {
        try {
          await authorise(variables)
          onAuthentication()
          return true
        } catch (e) {
          throw new Error('Wrong email or password')
        }
      }}
      onRequestRegistration={onRequestRegistration}
    />
  )
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .email()
    .required(),
  password: yup
    .string()
    .label('Password')
    .required()
    .max(4095, 'You know your Django')
} as {
  [prop in keyof FormFields]: any
})

export const SignInFormContent: React.FC<{
  onSubmit: (vars: FormFields) => Promise<boolean>
  onRequestRegistration: () => void
}> = ({ onSubmit, onRequestRegistration = noop }) => {
  return (
    <React.Fragment>
      <FormSection>
        {onRequestRegistration && (
          <Typography variant="body1">
            Or{' '}
            <span
              sx={{ fontWeight: 700, color: 'primary', cursor: 'pointer' }}
              onMouseDown={() => onRequestRegistration()}
            >
              register
            </span>{' '}
            instead
          </Typography>
        )}
      </FormSection>

      <Formik<FormFields>
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            setSubmitting(true)
            await onSubmit(values)
            setSubmitting(false)
          } catch (e) {
            setSubmitting(false)
            setFieldError(FORM_GENERAL_ERROR, e.toString())
          }
        }}
      >
        {({ handleReset, handleSubmit, isSubmitting }) => (
          <FormWrapper onReset={handleReset} onSubmit={handleSubmit}>
            <FormSection>
              <Input required fullWidth name="email" autoComplete="email" />
              <Input type="password" required fullWidth name="password" />

              <FormError>
                We couldn't create your account. If you’ve already created one,
                try{' '}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    onRequestRegistration()
                  }}
                >
                  Signing In
                </a>{' '}
                instead.
              </FormError>

              <FormGrid width="50%">
                <ProgressButton
                  size="large"
                  type="submit"
                  loading={isSubmitting}
                >
                  Sign in
                </ProgressButton>
              </FormGrid>
            </FormSection>
          </FormWrapper>
        )}
      </Formik>
    </React.Fragment>
  )
}
