import * as React from 'react'
import { ThemeProvider } from 'theme-ui'
import { Global } from '@emotion/core'
import { isMobile } from 'react-device-detect'
import { memoize, times } from 'lodash'
import { REACT_APP_ROOT } from 'symbols'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const fib = memoize((x: number): number => (x === 0 ? 0 : x + fib(x - 1)))

// example theme.js
export const theme = {
  space: times(10, i => fib(i) * 3),
  fontSizes: [8, 10, 12, 14, 16, 20, 24, 32, 48, 64, 96],
  lineHeights: {
    body: 1.3,
    heading: 1.125
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'neue-haas-grotesk-display, system-ui, sans-serif',
    monospace: 'Menlo, monospace'
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#e20613',
    secondary: '#E30613',
    black: '#4b3a3d',
    contrast: [
      'rgb(248, 248, 248)',
      '#E4E0E1',
      '#BDADB1',
      '#AF969D',
      '#5F4A4F'
    ],
    gray: [
      '#faf9f9',
      '#f0eced',
      '#e5dfe0',
      '#d9d0d2',
      '#cdc0c2',
      '#bfafb1',
      '#ae9a9e',
      '#9b8287',
      '#816469',
      '#4b3a3d'
    ],
    red: [
      '#fceaed',
      '#f9d3d9',
      '#f6b8c3',
      '#f298a7',
      '#ec6c81',
      '#e10d30',
      '#cb0b2b',
      '#b20a26',
      '#94081f',
      '#6a0616'
    ],
    orange: [
      '#fbede5',
      '#f8d9c9',
      '#f4c2a9',
      '#efa884',
      '#e98654',
      '#e1540d',
      '#cb4b0b',
      '#b1420a',
      '#933608',
      '#682706'
    ],
    yellow: [
      '#fbf6df',
      '#f6edbd',
      '#f2e398',
      '#edd86f',
      '#e7cc41',
      '#e1be0d',
      '#cbab0b',
      '#b2970a',
      '#947d08',
      '#6b5a06'
    ],
    lime: [
      '#f2fbe0',
      '#e4f7bf',
      '#d5f29a',
      '#c4ed71',
      '#b0e743',
      '#9ae10d',
      '#8bcb0b',
      '#7ab30a',
      '#659408',
      '#496b06'
    ],
    green: [
      '#e8fbe4',
      '#d0f8c8',
      '#b3f4a7',
      '#92ef80',
      '#69e950',
      '#30e10d',
      '#2bcb0b',
      '#26b30a',
      '#1f9408',
      '#176b06'
    ],
    teal: [
      '#e5fbed',
      '#c9f8d9',
      '#a9f4c2',
      '#82efa7',
      '#52e985',
      '#0de154',
      '#0bcb4c',
      '#0ab342',
      '#089437',
      '#066b28'
    ],
    cyan: [
      '#e5fbf8',
      '#c8f8f0',
      '#a7f4e7',
      '#80efdd',
      '#50e9cf',
      '#0de1be',
      '#0bcbac',
      '#0ab397',
      '#08957d',
      '#066c5b'
    ],
    blue: [
      '#e4f3fb',
      '#c6e7f7',
      '#a4d9f3',
      '#7dc9ee',
      '#4db4e9',
      '#0d9ae1',
      '#0b8bcb',
      '#0a79b2',
      '#086493',
      '#064869'
    ],
    indigo: [
      '#e9ecfc',
      '#d0d7f9',
      '#b4bff5',
      '#92a2f1',
      '#647beb',
      '#0d30e1',
      '#0b2bca',
      '#0a25b0',
      '#081e90',
      '#051564'
    ],
    violet: [
      '#f0eafc',
      '#dfd2f9',
      '#ccb7f6',
      '#b596f2',
      '#966aec',
      '#540de1',
      '#4b0bca',
      '#420ab1',
      '#360891',
      '#260566'
    ],
    fuschia: [
      '#f9eafc',
      '#f3d2f9',
      '#ebb7f6',
      '#e295f1',
      '#d668ec',
      '#be0de1',
      '#ab0bcb',
      '#960ab2',
      '#7c0893',
      '#59066a'
    ],
    pink: [
      '#fceaf6',
      '#f9d2ec',
      '#f6b7e1',
      '#f296d3',
      '#ec6ac1',
      '#e10d9a',
      '#cb0b8b',
      '#b20a7a',
      '#940865',
      '#6a0649'
    ]
  },
  borders: {
    light: '1px solid #E8E8E8',
    lightThick: '2px solid #E8E8E8',
    semiLight: '1px solid #e5dfe0',
    semiLightThick: '2px solid #e5dfe0',
    mid: '1px solid #BDADB1',
    midThick: '2px solid #BDADB1',
    dark: '1px solid black',
    strongThick: '2px solid black'
  },
  shadows: {
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    none: 'none'
  },
  card: {
    boxShadow: 'xl',
    p: 3,
    backgroundColor: 'background',
    borderRadius: 8
  },
  chip: {
    display: 'inline-block',
    borderRadius: 100
  },
  primaryBlock: {
    bg: 'primary',
    color: 'white'
  },
  clickableChip: {
    border: '1.5px solid #FF5C5C',
    borderColor: 'secondary',
    color: 'secondary',
    cursor: 'pointer'
  },
  primaryOutline: {
    borderColor: 'primary',
    borderWidth: 1.5,
    borderStyle: 'solid',
    color: 'primary'
  },
  columns: {
    display: 'flex',
    flexDirection: 'row'
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    alignItems: 'flex-start'
  },
  button: {
    border: 'none',
    ':disabled': { opacity: 0.3, cursor: 'unset' },
    '&:hover:not(:disabled):not(:active)': { opacity: 0.7 },
    px: 3,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    color: 'white',
    backgroundColor: 'unset',
    textAlign: 'center',
    fontSize: 3,
    fontWeight: 700,
    outline: 'none'
  },
  buttonRaised: {
    bg: 'primary',
    px: 4,
    py: 2,
    boxShadow: 'inner',
    color: 'white',
    ':hover': {
      color: 'white'
    }
  },
  heading: {
    m: 0,
    fontSize: '25px',
    fontWeight: 'bold'
  },
  subheading: {
    m: 0,
    fontSize: '12px',
    fontWeight: 'bold'
  },
  description: {
    letterSpacing: '-0.02em',
    fontSize: [2, 3],
    color: '#616161'
  },
  body: {
    fontSize: 3,
    fontWeight: 400
  },
  boxLink: {
    textDecoration: 'unset',
    '&:hover:not(:disabled):not(:active)': { opacity: 0.7 }
  },
  caption: {
    letterSpacing: '-0.02em',
    fontSize: 2,
    lineHeight: '110%',
    color: '#616161'
  },
  breakpoints: ['678px', '1024px'],
  paneHeight: isMobile ? window.innerHeight * 0.66 : window.innerHeight * 0.5
}

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: theme.fonts.body
  },
  palette: {
    primary: {
      main: theme.colors.primary
    },
    secondary: {
      main: theme.colors.secondary
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        background: 'white'
      }
    }
  }
})

const heading = {
  fontFamily: theme.fonts.heading,
  lineHeight: theme.lineHeights.heading,
  fontWeight: 600
}

const Theme: React.FC = ({ children, ...props }) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme} {...props}>
      <Global
        styles={theme => ({
          html: {
            margin: 0,
            padding: 0,
            height: '100%',
            overflow: 'hidden'
          },
          body: {
            color: theme.colors.text,
            backgroundColor: theme.colors.background,
            fontFamily: theme.fonts.body,
            margin: 0,
            padding: 0,
            height: '100%',
            overflow: 'hidden'
          },
          [`#${REACT_APP_ROOT}`]: {
            height: '100%',
            overflow: 'hidden'
          },
          p: {
            lineHeight: theme.lineHeights.body
          },
          h1: { ...heading },
          h2: { ...heading },
          h3: { ...heading },
          h4: { ...heading },
          h5: { ...heading }
        })}
      />
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
)

export default Theme
